import "react-calendar/dist/Calendar.css";
import "react-toastify/dist/ReactToastify.css";

import { ScrollToTop } from "components/ScrollTop";
import { queryClient } from "helper/query";
import { useEffect, useRef } from "react";
import { QueryClientProvider } from "react-query";
import { ToastContainer } from "react-toastify";
import utils from "utils";
import RoutePage from "./route";
import { config } from "helper/config";
import {
  clearTokenAuth,
  getTokenAuth,
  setTokenAuth,
  setUserData,
} from "helper/localStorage";
import { authRefresh } from "service/auth";
import { getProfile } from "service/profile";

function App() {
  const contentRef = useRef<HTMLDivElement>(null);

  const onScrollToTop = () => {
    if (!window.top) return;

    utils.postMessageScrollTop();
  };

  const onResize = () => {
    if (!window.top) return;

    const message = JSON.stringify({
      type: "height",
      value: contentRef.current?.scrollHeight,
    });
    window.top.postMessage(message, "*");
  };

  const refreshToken = async () => {
    if (!getTokenAuth()) {
      return;
    }

    try {
      const res = await authRefresh();
      const data = res.data;
      const token = data.token;

      await setTokenAuth(token);
      setUserData({
        username: data.username,
        email: data.email,
        profile_url: data.profile_url,
        role_name: data.role_name,
      });
    } catch (err) {
      await clearTokenAuth();

      console.log("Error :", err);
      window.location.reload();
    }
  };

  const initProfile = () => {
    getProfile()
      .then((val) => {
        const offset = Math.round(val.data.timezone.offset / 3600);
        if (offset === config.timezoneOffsetHour) {
          return;
        }

        config.timezoneOffsetHour = offset;
        window.location.reload();
      })
      .catch(() => console.log("Not Loged Yet!"));
  };

  // Start observing the element when the component is mounted
  useEffect(() => {
    refreshToken();

    initProfile();

    const element = contentRef.current;
    if (!element) return;

    const observer = new ResizeObserver(onResize);
    observer.observe(element);

    return () => observer.disconnect();
  }, []);

  return (
    <div className="px-4 w-full" ref={contentRef}>
      <div className="max-w-7xl mx-auto pt-6 pb-24 h-full">
        <QueryClientProvider client={queryClient}>
          <RoutePage></RoutePage>
        </QueryClientProvider>
        <ToastContainer />
        <ScrollToTop onScrollToTop={onScrollToTop} />
      </div>
    </div>
  );
}

export default App;
