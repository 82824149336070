/* eslint-disable react-hooks/exhaustive-deps */
import {
  ButtonBlack,
  ButtonBlue,
  ButtonBorder,
  ButtonGold,
} from "components/Button";
import { InputText } from "components/Input";
import { Title } from "components/Text";
import { config } from "helper/config";
import {
  clearTokenAuth,
  getTokenAuth,
  setNameCookies,
  setRoleCookies,
  setTokenAuth,
  setUserData,
} from "helper/localStorage";
import { ShieldTick } from "iconsax-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  authLogin,
  authLoginSosmed,
  authRegistration,
  authRegistrationSosmed,
  checkUserGoogle,
  requestOTP,
  verifyEmail,
} from "service/auth";
import { getLatestPoint, getProfile, updateProfile } from "service/profile";
import { checkPromo } from "service/promo";
import { bookReservation } from "service/reservation";
import { getSchedule } from "service/schedule";
import { getSpecialist, getSpecialistRates } from "service/specialist";
import utils, { CapitalizeFirstLetter } from "utils";
import validator from "validator";
import { ewallets, transfers } from "./content";
import Stepper from "components/stepper";
import { ButtonModalPolicy } from "components/Modal";
import { LoginForm } from "components/auth/Login";
import { RegisterForm } from "components/auth/Register";
import { useGoogleLogin } from "@react-oauth/google";
import OtpInput from "components/cotp";
import SkeletonPayment from "components/skeleton/SkeletonPayment";

const VIDEO_CALL = "video_call";
const VOICE_CALL = "voice_call";
const PREVIEW = "preview";

export default function Payment() {
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const specialistCode = params.specialist_code as string;
  const appointmentType = searchParams.get("appointmentType") ?? VOICE_CALL;
  const scheduleDetailCode = params.schedule_detail_code as string;

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authType, setAuthType] = useState(config.constant.signup);

  const [point, setPoint] = useState(0);
  const [promo, setPromo] = useState(0);
  const [promoCode, setPromoCode] = useState(
    searchParams.get("promoCode") ?? ""
  );

  const [paymentChannel, setPaymentChannel] = useState("");
  const [paymentOption, setPaymentOption] = useState("");

  const isValidAuth = () => {
    const validPassword = password.length > 6;
    const validName = name.length > 3;
    const validEmail = validator.isEmail(email);

    if (authType === config.constant.login) {
      return validEmail && validPassword;
    }

    if (authType === config.constant.signup) {
      return validName && validPassword && validEmail;
    }

    return true;
  };

  const onGetSchedule = () => {
    return getSchedule({
      memberCode: specialistCode,
      scheduleDetailCode: scheduleDetailCode,
    }).then((val) => val.data);
  };

  const onFetchProfile = () => {
    return () =>
      getSpecialist({ memberCode: specialistCode }).then((val) => val.data);
  };

  const onGetProfile = () => {
    if (getTokenAuth() === null) {
      return () => null;
    }

    return () => {
      try {
        return getProfile().then((res) => res.data);
      } catch (error) {
        return clearTokenAuth();
      }
    };
  };

  const onGetLatestPoint = () => {
    return () => {
      return getLatestPoint()
        .then((val) => val.data)
        .catch(() => null);
    };
  };

  const onGetSpecialistRates = () => {
    return getSpecialistRates({ memberCode: specialistCode }).then(
      (val) => val.data ?? {}
    );
  };

  // USE QUERY //
  const querySchedule = useQuery("getSchedule", onGetSchedule);
  const schedule = querySchedule.data;

  const querySpecialist = useQuery("getSpecialist", onFetchProfile());
  const specialist = querySpecialist.data ?? {};

  const queryProfile = useQuery("getProfile", onGetProfile());
  const profile = queryProfile.data ?? {};

  const queryRates = useQuery("getSpecialistRates", onGetSpecialistRates);
  const rates = queryRates.data ?? {};

  const queryLatestPoint = useQuery(
    "getLatestPoint",
    onGetLatestPoint(),
    config.useQuery.fetchOnce as any
  );
  const latestPoint = queryLatestPoint.data;

  useEffect(() => {
    const data = querySchedule.data;
    if (!data) {
      return;
    }

    const status = data.status;
    if (status !== "active" && status !== "available") {
      navigate(`/booking/${specialistCode}`);
    }
  }, [querySchedule.data]);

  useEffect(() => {
    if (queryProfile.data != null) {
      setAuthType(config.constant.loged);
    }
  }, [queryProfile.data]);

  const getRate = () => {
    for (const item of rates.rates ?? []) {
      if (item.format_call === appointmentType) {
        return item.price;
      }
    }

    return 0;
  };

  const isFullPoint = () => point > 0 && total === 0;
  const hourlyPrice = getRate();
  const total = hourlyPrice - point - promo;
  const isEnabledPay =
    ((paymentChannel && paymentOption) || isFullPoint()) &&
    authType === config.constant.loged;

  useEffect(() => {
    if (searchParams.get("promoCode") && hourlyPrice !== 0) onCheckPromo();
  }, [promoCode, hourlyPrice]);

  const isEnabledClaim = promoCode !== "";

  const isEnabledOVOPay = phone !== "";

  const [showOVOSection, setShowOVOSection] = useState(false);

  const onSubmit = async () => {
    if (paymentOption === "ovo") {
      setShowOVOSection(true);

      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();
    } else {
      submitPayment();
    }
  };

  const changeOtherPayment = () => {
    setShowOVOSection(false);

    window.scrollTo({ behavior: "smooth", top: 0 });
    utils.postMessageScrollTop();
  };

  const isValidPhone = () => {
    const validPhone = validator.isMobilePhone(phone);

    return validPhone;
  };

  const submitPaymentOvo = async () => {
    if (!isValidPhone()) {
      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();

      return toast.warning("Nomor HP Anda Tidak Valid");
    }

    let paymentType = "payment_gateaway";

    const bookResp = await bookReservation({
      formatCall: appointmentType,
      paymentChannel: paymentChannel,
      paymentOption: paymentOption,
      paymentType: paymentType,
      paymentPhone: phone,
      scheduleDetailCode: scheduleDetailCode,
      specialistCode: specialistCode,
      promoCode: promoCode,
      promoApplied: promo,
    });
    const data = bookResp.data;
    const reservationCode = data.reservation_code;

    if (paymentChannel === "ewallet") {
      const redirect =
        data.payment_gateaway_data.raw_response.response.checkout_url;

      window.open(redirect, "_blank");
    }

    navigate(`/booking/reservation/${reservationCode}`);
  };

  const submitPreview = async () => {
    let paymentType = "point";

    const bookResp = await bookReservation({
      formatCall: appointmentType,
      paymentChannel: paymentChannel,
      paymentOption: paymentOption,
      paymentType: paymentType,
      scheduleDetailCode: scheduleDetailCode,
      specialistCode: specialistCode,
      promoCode: promoCode,
      promoApplied: promo,
    });
    const data = bookResp.data;
    const reservationCode = data.reservation_code;

    if (paymentChannel === "ewallet") {
      const redirect =
        data.payment_gateaway_data.raw_response.response.checkout_url;

      window.open(redirect, "_blank");
    }

    navigate(`/booking/reservation/${reservationCode}`);
  };

  const submitPayment = async () => {
    let paymentType = "payment_gateaway";
    if (point > 0) {
      paymentType = "point";
    }

    const bookResp = await bookReservation({
      formatCall: appointmentType,
      paymentChannel: paymentChannel,
      paymentOption: paymentOption,
      paymentType: paymentType,
      scheduleDetailCode: scheduleDetailCode,
      specialistCode: specialistCode,
      promoCode: promoCode,
      promoApplied: promo,
    });
    const data = bookResp.data;
    const reservationCode = data.reservation_code;

    if (paymentChannel === "ewallet") {
      const redirect =
        data.payment_gateaway_data.raw_response.response.checkout_url;

      window.open(redirect, "_blank");
    }

    navigate(`/booking/reservation/${reservationCode}`);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingRegister, setIsLoadingRegister] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const onSignup = async () => {
    if (authType !== config.constant.signup) {
      return;
    }

    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }

    setIsLoadingRegister(true);

    try {
      // Signup First
      const res = await authRegistration({
        email: email,
        password: password,
      });
      const token = res.data.token;

      setTokenAuth(token);

      const resProfile = await updateProfile({
        profile_url: "",
        username: name,
      });

      const data = resProfile.data;

      setNameCookies(data.username);
      setRoleCookies(data.role_name);

      setUserData({
        member_code: data.member_code,
        username: data.username,
        email: data.email,
        profile_url: data.profile_url,
        role_name: data.role_name,
      });

      setIsLoadingRegister(false);
      requestOTP().then((res) => {
        setCanResend(false);
        setShowVerifyOTP(true);
        setTimeLeft(30);
      });
    } catch (error) {
      toast.error(error as string);
    }
  };

  const onLogin = async () => {
    if (authType !== config.constant.login) {
      return;
    }

    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }

    setIsLoading(true);
    try {
      // Login First
      authLogin({
        email: email,
        password: password,
      })
        .then((res) => {
          const data = res.data;
          const token = data.token;
          setIsLoading(false);
          if (data.role_name !== "member" && data.role_name !== "member_org") {
            toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
            window.scrollTo({ behavior: "smooth", top: 0 });
            utils.postMessageScrollTop();
          } else {
            setTokenAuth(token);
            setNameCookies(data.username);
            setRoleCookies(data.role_name);
            setUserData({
              member_code: data.member_code,
              username: data.username,
              email: data.email,
              profile_url: data.profile_url,
              role_name: data.role_name,
            });

            queryProfile.refetch();
            navigate(
              `/booking/${specialistCode}/${scheduleDetailCode}?appointmentType=${appointmentType}&promoCode=${promoCode}`
            );
          }
        })
        .catch((err) => {
          setIsLoading(false);
          toast.error(err);
        });
    } catch (error) {
      toast.error(error as string);
    }
  };

  const onSubmitGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      loginGoogle("gmail", tokenResponse.access_token, userInfo.email);
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const loginGoogle = async (type: string, token: string, emailGoogle: any) => {
    setIsLoadingGoogle(true);
    authLoginSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
    })
      .then((res) => {
        setIsLoadingGoogle(false);
        const data = res.data;
        const token = data.token;

        if (data.role_name !== "member" && data.role_name !== "member_org") {
          toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
          window.scrollTo({ behavior: "smooth", top: 0 });
          utils.postMessageScrollTop();
        } else {
          setTokenAuth(token);
          setNameCookies(data.username);
          setRoleCookies(data.role_name);
          setUserData({
            member_code: data.member_code,
            username: data.username,
            email: data.email,
            profile_url: data.profile_url,
            role_name: data.role_name,
          });

          queryProfile.refetch();

          navigate(
            `/booking/${specialistCode}/${scheduleDetailCode}?appointmentType=${appointmentType}&promoCode=${promoCode}`
          );
        }
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onRegisterGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      registerGoogle(
        "gmail",
        tokenResponse.access_token,
        userInfo.email,
        userInfo.name
      );
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const registerGoogle = async (
    type: string,
    token: string,
    emailGoogle: string,
    nameGoogle: string
  ) => {
    setIsLoadingGoogle(true);
    authRegistrationSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
      code: "",
    })
      .then((res) => {
        const data = res.data;
        const token = data.token;

        setTokenAuth(token);

        updateProfile({
          profile_url: "",
          username: nameGoogle,
        }).then((val) => {
          const updatedData = val.data;

          setNameCookies(updatedData.username);
          setRoleCookies(data.role_name);

          setUserData({
            member_code: updatedData.member_code,
            username: updatedData.username,
            email: updatedData.email,
            profile_url: updatedData.profile_url,
            role_name: updatedData.role_name,
          });

          setIsLoadingGoogle(false);
          requestOTP().then((res) => {
            setCanResend(false);
            setShowVerifyOTP(true);
            setTimeLeft(30);
          });
        });
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        if (err.toString().toLowerCase().includes("email already exist")) {
          loginGoogle("gmail", token, emailGoogle);
        } else {
          toast.warn(CapitalizeFirstLetter(err));
        }
      });
  };

  const [timeLeft, setTimeLeft] = useState(0);
  const [canResend, setCanResend] = useState(false);
  const [showVerifyOTP, setShowVerifyOTP] = useState(false);
  const [isLoadingVerify, setIsLoadingVerify] = useState(false);
  const [otp, setOtp] = useState("");
  const [isBtnVerifyDisabled, setBtnVerifyDisabled] = useState(true);

  useEffect(() => {
    if (timeLeft === 0) {
      setCanResend(true);
      setTimeLeft(0);
    }

    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, 1000);

    return () => clearInterval(intervalId);
  }, [timeLeft]);

  const onChangeOTP = (value: string) => {
    setOtp(value);
  };

  useEffect(() => {
    setBtnVerifyDisabled(otp.trim().length < 4);
  }, [otp]);

  const onResend = async () => {
    requestOTP().then((res: any) => {
      setTimeLeft(30);
      setCanResend(false);
    });
  };

  const onVerify = async () => {
    setIsLoadingVerify(true);
    verifyEmail({
      pin: otp,
    })
      .then((res: any) => {
        setShowVerifyOTP(false);
        setIsLoadingVerify(false);
        queryProfile.refetch();
        navigate(
          `/booking/${specialistCode}/${scheduleDetailCode}?appointmentType=${appointmentType}&promoCode=${promoCode}`
        );
      })
      .catch((err: string) => {
        setIsLoadingVerify(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onChangePayment = (channel: string, option: string) => {
    if (total === 0) {
      return toast.success("payment already used point");
    }

    setPaymentChannel(channel);
    setPaymentOption(option);
  };

  const onUsePoint = () => {
    if (point === 0) {
      if (total > latestPoint.latest_point) {
        setPoint(latestPoint.latest_point);
      } else {
        setPoint(total);

        setPaymentChannel("");
        setPaymentOption("");
      }
    } else {
      setPoint(0);
    }
  };

  const onCheckPromo = async () => {
    if (promoCode === "" || hourlyPrice === 0) {
      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();

      return toast.warn("please input promo code first");
    } else {
      checkPromo({
        amount: hourlyPrice,
        promoCode: promoCode,
      })
        .then((res) => {
          setPromo(res.data.promo_applied);
          toast.success("Berhasil Memakai Promo");
          window.scrollTo({ behavior: "smooth", top: 0 });
          utils.postMessageScrollTop();
        })
        .catch((err) => {
          toast.warn(CapitalizeFirstLetter(err));
          window.scrollTo({ behavior: "smooth", top: 0 });
          utils.postMessageScrollTop();
        });
    }
  };

  if (
    querySpecialist.isLoading ||
    querySchedule.isLoading ||
    queryProfile.isLoading
  ) {
    return <SkeletonPayment></SkeletonPayment>;
  }

  return (
    <div>
      <Stepper step={2}></Stepper>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:basis-7/12">
          {showOVOSection ? (
            <div>
              <div className="md:hidden lg:basis-5/12 lg:pl-10">
                <BookingDetailSection />
              </div>

              <div className="md:p-8 my-4 md:my-0 md:bg-white lg:rounded-xl flex flex-col gap-4">
                <div className="w-full flex flex-col items-center justify-center gap-4">
                  <img src="/images/svg/ovo.svg" className="w-20" alt="ovo" />
                  <p>{utils.getLangByKey("booking_ovo_title")}</p>
                </div>
                <label
                  className="text-lg font-medium text-black-app"
                  htmlFor="phone"
                >
                  {utils.getLangByKey("booking_ovo_phone")}*
                </label>
                <InputText
                  placeholder="Nomor yang terdaftar di OVO"
                  onChange={(val) => setPhone(val)}
                  defaultValue={phone}
                  type="tel"
                  id="phone"
                ></InputText>
                <ButtonBlue
                  className={`py-3 px-8 mt-4 ${
                    isEnabledOVOPay ? "" : "bg-neutral-200"
                  }`}
                  onClick={submitPaymentOvo}
                >
                  <p className="text-sm font-medium">
                    {utils.getLangByKey("booking_ovo_pay_now")}
                  </p>
                </ButtonBlue>
                <ButtonBorder onClick={changeOtherPayment}>
                  {utils.getLangByKey("booking_ovo_change_payment")}
                </ButtonBorder>
              </div>
            </div>
          ) : showVerifyOTP ? (
            <div className="border-0 rounded-lg shadow-lg flex justify-center w-full bg-white focus:outline-none">
              <div className="text-center px-20 py-16">
                <p className="text-lg font-semibold">
                  {utils.getLangByKey("verify_title")}
                </p>
                <p className="text-sm font-normal text-text-grey mt-4">
                  {utils.getLangByKey("verify_subtitle")}
                </p>
                <p className="text-sm font-medium text-text-grey mb-6">
                  {email}
                </p>
                <div className="flex justify-center">
                  <OtpInput
                    value={otp}
                    valueLength={4}
                    onChange={onChangeOTP}
                  />
                </div>
                <div className="mt-6">
                  {canResend ? (
                    <div className="flex justify-center">
                      <p className="text-sm text-text-grey">
                        {utils.getLangByKey("verify_code_unreceived")}
                      </p>
                      <p
                        className="text-sm font-medium text-text-dark-blue ml-1 cursor-pointer"
                        onClick={() => {
                          onResend();
                        }}
                      >
                        {utils.getLangByKey("verify_code_resend")}
                      </p>
                    </div>
                  ) : (
                    <p className="text-sm text-text-grey">
                      {utils.getLangByKey("verify_please_wait") +
                        timeLeft +
                        " " +
                        utils.getLangByKey("verify_time_wait")}
                    </p>
                  )}
                </div>
                <button
                  type="button"
                  disabled={isBtnVerifyDisabled}
                  className="text-white flex items-center justify-center bg-black hover:bg-black/60 rounded-full w-full h-10 mt-4 disabled:bg-slate-400	disabled:cursor-not-allowed"
                  onClick={() => onVerify()}
                >
                  <strong>
                    {isLoadingVerify
                      ? utils.getLangByKey("verify_btn_loading")
                      : utils.getLangByKey("verify_btn")}
                  </strong>
                  {isLoadingVerify ? (
                    <div
                      className="h-5 w-5 ml-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
                      role="status"
                    ></div>
                  ) : null}
                </button>
              </div>
            </div>
          ) : (
            <div className="flex flex-col lg:gap-8">
              {authType === config.constant.login ? (
                <LoginForm
                  email={email}
                  password={password}
                  isLoading={isLoading}
                  isLoadingGoogle={isLoadingGoogle}
                  isValidInput={isValidAuth()}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setAuthType={setAuthType}
                  onSubmit={onLogin}
                  onSubmitGoogle={onSubmitGoogle}
                ></LoginForm>
              ) : null}

              {authType === config.constant.loged ? (
                <LogedSection
                  onAuthTypeLogin={() => setAuthType(config.constant.login)}
                  profile={profile}
                  showBook={appointmentType === PREVIEW}
                  onSubmit={submitPreview}
                ></LogedSection>
              ) : null}

              {authType === config.constant.signup ? (
                <RegisterForm
                  isValidInput={isValidAuth()}
                  email={email}
                  name={name}
                  password={password}
                  isLoading={isLoadingRegister}
                  isLoadingGoogle={isLoadingGoogle}
                  setAuthType={setAuthType}
                  setEmail={setEmail}
                  setName={setName}
                  setPassword={setPassword}
                  onSubmit={onSignup}
                  onSubmitGoogle={onRegisterGoogle}
                ></RegisterForm>
              ) : null}

              <div className="md:hidden lg:basis-5/12 lg:pl-10">
                <BookingDetailSection />
              </div>

              {authType === config.constant.loged &&
              appointmentType !== PREVIEW ? (
                <div className="md:p-8 my-4 md:my-0 md:bg-white lg:rounded-xl flex flex-col gap-6">
                  <div className="font-medium text-xl text-black-app">
                    {utils.getLangByKey("global_payment_method")}
                  </div>
                  <div>
                    <div className="font-semibold text-sm text-black-app">
                      E-Wallet
                    </div>
                    <div className="flex flex-col gap-3 my-4">
                      {ewallets.map((val) => {
                        return (
                          <ItemPayment
                            key={val.label}
                            imgSrc={val.imgSrc}
                            label={val.label}
                            isChecked={
                              paymentChannel === val.payment_channel &&
                              paymentOption === val.payment_option
                            }
                            onClick={() =>
                              onChangePayment(
                                val.payment_channel,
                                val.payment_option
                              )
                            }
                          ></ItemPayment>
                        );
                      })}
                    </div>
                    <div className="font-semibold text-sm text-black-app">
                      {utils.getLangByKey("booking_bank_transfer")}
                    </div>
                    <div className="flex flex-col gap-3 my-4">
                      {transfers.map((val) => {
                        return (
                          <ItemPayment
                            key={val.label}
                            imgSrc={val.imgSrc}
                            label={val.label}
                            isChecked={
                              paymentChannel === val.payment_channel &&
                              paymentOption === val.payment_option
                            }
                            onClick={() =>
                              onChangePayment(
                                val.payment_channel,
                                val.payment_option
                              )
                            }
                          ></ItemPayment>
                        );
                      })}
                    </div>

                    <div className="font-semibold text-sm text-black-app mb-4">
                      QRIS
                    </div>
                    <ItemPayment
                      imgSrc="/images/payment/QRIS.png"
                      label="QRIS"
                      isChecked={paymentChannel === "qris"}
                      onClick={() => onChangePayment("qris", "dana")}
                    ></ItemPayment>

                    {latestPoint ? (
                      <div className="cursor-pointer">
                        <div className="font-medium text-xl mt-8 text-black-app">
                          {utils.getLangByKey("booking_point_maxi")}
                        </div>
                        <div
                          className="border rounded-md px-5 py-1 flex justify-between items-center my-3"
                          onClick={onUsePoint}
                        >
                          <div className="flex items-center">
                            <img
                              className="object-scale-down w-11 h-11"
                              src="/logo192.png"
                              alt=""
                            />
                            <div className="ml-4">
                              {latestPoint.latest_point ?? 0} Points
                            </div>
                          </div>
                          <div>
                            {point === 0 ? (
                              <div className="w-6 h-6 border rounded-full"></div>
                            ) : (
                              <img
                                className="w-6 h-6"
                                src={"/images/svg/check.svg"}
                                alt=""
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <div className="font-semibold text-xl text-black-app mt-4">
                      Promo
                    </div>
                    <div className="my-3 flex">
                      <InputText
                        onChange={(val) => setPromoCode(val)}
                        placeholder={utils.getLangByKey("booking_promo_hint")}
                        value={promoCode}
                        type="text"
                      ></InputText>
                      <ButtonGold
                        className={`py-3 px-8 flex items-center ml-4 ${
                          isEnabledClaim ? "" : "bg-neutral-200"
                        }`}
                        onClick={onCheckPromo}
                      >
                        {utils.getLangByKey("booking_promo_claim")}
                      </ButtonGold>
                    </div>
                  </div>
                  <ButtonBlue
                    className={`py-3 px-8 ${
                      isEnabledPay ? "" : "bg-neutral-200"
                    }`}
                    onClick={onSubmit}
                  >
                    <p className="text-lg font-medium">
                      {paymentOption === "ovo"
                        ? utils.getLangByKey("booking_payment_ovo")
                        : utils.getLangByKey("global_pay")}
                    </p>
                  </ButtonBlue>
                </div>
              ) : null}
            </div>
          )}
        </div>

        <div className="hidden md:block lg:basis-5/12 lg:pl-10">
          <BookingDetailSection />
        </div>
      </div>
    </div>
  );

  function BookingDetailSection() {
    return (
      <div className="md:p-8 my-4 md:my-0 md:bg-white lg:rounded-xl">
        <Title>{utils.getLangByKey("booking_detail_title")}</Title>
        <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
          <div className="shrink-0">
            <img
              className="rounded-full w-16 h-16"
              src={`${config.AWS_URL_IMAGES}${specialist.profile_url}`}
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <div className="text-xl font-medium">{specialist.username}</div>
              <div className="text-neutral-secondary">
                {specialist.specialist_title}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">
                {utils.getLangByKey("global_appointment")}
              </div>
              <div className="text-neutral-800">
                {appointmentType === VIDEO_CALL
                  ? utils.getLangByKey("global_video_call") + " 60 "
                  : appointmentType === VOICE_CALL
                  ? utils.getLangByKey("global_voice_call") + " 60 "
                  : utils.getLangByKey("global_preview") + " 15 "}
                {utils.getLangByKey("global_minutes")}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">
                {utils.getLangByKey("global_date")}
              </div>
              <div className="text-neutral-800">
                {moment(schedule.date).format("MMMM D, YYYY")}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">
                {utils.getLangByKey("global_time")}
              </div>
              <div className="text-neutral-800">
                {moment(schedule.time_from, "HH:mm").format("hh:mm A")}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start mt-6">
          <div className="flex justify-between w-full">
            <div className="text-lg text-neutral-secondary">Subtotal</div>
            <div className="text-lg text-neutral-primary">
              {utils.currencyFormatter(hourlyPrice)}
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="text-lg text-neutral-secondary">Promo</div>
            <div className="text-lg text-neutral-primary">
              {promo > 0 ? `-${utils.currencyFormatter(promo)}` : "Rp0"}
            </div>
          </div>
        </div>

        <div className="flex justify-between text-xl font-medium text-black-app mt-6">
          <div>Total</div>
          <div>{total > 0 ? `${utils.currencyFormatter(total)}` : "Rp0"}</div>
        </div>

        <div className="mt-8 flex gap-5">
          <div>
            <ShieldTick variant="Bold" className="text-brand-dark"></ShieldTick>
          </div>
          <div className="text-neutral-secondary">
            {utils.getLangByKey("booking_detail_policy")}{" "}
            <ButtonModalPolicy></ButtonModalPolicy>
          </div>
        </div>
      </div>
    );
  }
}

function LogedSection(props: {
  profile: any;
  showBook: boolean;
  onAuthTypeLogin: () => void;
  onSubmit: () => void;
}) {
  return (
    <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
      <div className="font-medium text-xl text-black-app">
        Hi, {props.profile.username}
      </div>
      <div>
        {utils.getLangByKey("loged_title")} {props.profile.email}
      </div>
      <div>
        {utils.getLangByKey("loged_subtitle")}{" "}
        <span
          className="text-brand-dark cursor-pointer"
          onClick={props.onAuthTypeLogin}
        >
          {utils.getLangByKey("loged_switch")}
        </span>
      </div>

      {props.showBook ? (
        <div className="flex justify-end">
          <ButtonBlack
            className="w-48 h-10 flex justify-center items-center"
            onClick={props.onSubmit}
          >
            <p className="text-lg font-medium">
              {utils.getLangByKey("booking_book_session")}
            </p>
          </ButtonBlack>
        </div>
      ) : null}
    </div>
  );
}

function ItemPayment(props: {
  label: string;
  imgSrc: string;
  isChecked: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className="border rounded-md px-5 py-4 flex justify-between cursor-pointer"
      onClick={props.onClick}
    >
      <div className="flex">
        <img className="object-scale-down w-28 h-6" src={props.imgSrc} alt="" />
        <div>{props.label}</div>
      </div>
      <div>
        {props.isChecked ? (
          <img className="w-6 h-6" src="/images/svg/check.svg" alt="" />
        ) : (
          <div className="w-6 h-6 border rounded-full"></div>
        )}
      </div>
    </div>
  );
}
