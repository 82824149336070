import api from "./api";
import utils from "utils";

export const getExpertises = () => {
  return api
    .get("/v2/specialist/expertise", {
      params: {
        lang: utils.getLangActive().toLowerCase(),
      },
    })
    .then((val) => val.data);
};
