import axios from "axios";
import { config } from "helper/config";
import { getTokenAuth } from "helper/localStorage";

const api = axios.create({
	baseURL: process.env.REACT_APP_API_URL,
	headers: {
		"Content-type": "application/json; charset=UTF-8",
		"X-Channel": "app",
		"X-TIMEZONE": config.timezoneOffsetHour,
	},
});

// Add a request interceptor
api.interceptors.request.use(async (req) => {
	const token = getTokenAuth();

	req.headers['Authorization'] = token;

	return req;
});

// Add a response interceptor
api.interceptors.response.use(
	(resp) => resp,
	(error) => {
		let message = error.response.data.stat_msg;
		if (!message) {
			message = error.toString();
		}

		return Promise.reject(message);
	}
);

export default api;
