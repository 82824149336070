import { useGoogleLogin } from "@react-oauth/google";
import {
  ButtonBlack,
  ButtonBlue,
  ButtonBorder,
  ButtonGold,
} from "components/Button";
import { InputText } from "components/Input";
import { Loading } from "components/Loading";
import { ButtonModalPolicy } from "components/Modal";
import { Title } from "components/Text";
import { LoginForm } from "components/auth/Login";
import { RegisterForm } from "components/auth/Register";
import WaitingPaymentOvertime from "components/reservation/waiting_payment_overtime";
import SkeletonPayment from "components/skeleton/SkeletonPayment";
import { config } from "helper/config";
import {
  clearTokenAuth,
  getTokenAuth,
  setNameCookies,
  setRoleCookies,
  setTokenAuth,
  setUserData,
} from "helper/localStorage";
import { ShieldTick } from "iconsax-react";
import moment from "moment";
import { ewallets, transfers } from "pages/payment/content";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  authLogin,
  authLoginSosmed,
  authRegistration,
  authRegistrationSosmed,
  checkUserGoogle,
} from "service/auth";
import { getLatestPoint, getProfile, updateProfile } from "service/profile";
import { checkPromo } from "service/promo";
import { getReservation } from "service/reservation";
import {
  bookReservationOvertime,
  getReservationOvertime,
} from "service/reservation_overtime";
import utils, { CapitalizeFirstLetter } from "utils";
import validator from "validator";

const VIDEO_CALL = "video_call";

export default function Payment() {
  const params = useParams();
  const navigate = useNavigate();

  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [authType, setAuthType] = useState(config.constant.signup);

  const [point, setPoint] = useState(0);
  const [promo, setPromo] = useState(0);
  const [promoCode, setPromoCode] = useState("");
  const [paymentChannel, setPaymentChannel] = useState("");
  const [paymentOption, setPaymentOption] = useState("");

  const [status, setStatus] = useState("");
  const [overtimeData, setOvertimeData] = useState<any>();

  useEffect(() => {
    const token = getTokenAuth();
    if (!token) {
      navigate(`/booking/login?redirect_url=${window.location.pathname}`);
    }
  }, []);

  const reservationCode = params.reservation_code as string;
  const queryReservationOvertime = useQuery("getReservationOvertime", () =>
    getReservationOvertime({ reservationCode }).then((val) => val.data ?? {})
  );
  const reservationOvertime = queryReservationOvertime.data ?? {};

  const queryReservation = useQuery("getReservation", () =>
    getReservation({ reservationCode: reservationCode }).then(
      (val) => val.data ?? {}
    )
  );
  const reservation = queryReservation.data;

  const onGetProfile = () => {
    if (getTokenAuth() === null) {
      return () => null;
    }

    return async () => {
      try {
        return getProfile().then((res) => res.data);
      } catch (error) {
        await clearTokenAuth();
      }

      return null;
    };
  };

  const onGetLatestPoint = () => {
    return () => {
      return getLatestPoint()
        .then((val) => val.data)
        .catch(() => null);
    };
  };

  const isValidAuth = () => {
    const validPassword = password.length > 6;
    const validName = name.length > 3;
    const validEmail = validator.isEmail(email);

    if (authType === config.constant.login) {
      return validEmail && validPassword;
    }

    if (authType === config.constant.signup) {
      return validName && validPassword && validEmail;
    }

    return true;
  };

  // USE QUERY //
  const queryProfile = useQuery("getProfile", onGetProfile());
  const profile = queryProfile.data ?? {};

  const queryLatestPoint = useQuery(
    "getLatestPoint",
    onGetLatestPoint(),
    config.useQuery.fetchOnce as any
  );
  const latestPoint = queryLatestPoint.data;

  useEffect(() => {
    if (
      queryReservationOvertime.data &&
      reservationOvertime.status !== "created"
    ) {
      navigate(`/booking/reservation/${reservationCode}`);
    }
  }, [queryReservationOvertime.data]);

  useEffect(() => {
    if (queryProfile.data != null) {
      setAuthType(config.constant.loged);
    }
  }, [queryProfile.data]);

  const isFullPoint = () => point > 0 && total === 0;
  const hourlyPrice = reservationOvertime.price ?? 0;
  const total = hourlyPrice - point - promo;
  const isEnabledPay =
    ((paymentChannel && paymentOption) || isFullPoint()) &&
    authType === config.constant.loged;

  const isEnabledClaim = promoCode !== "";

  const isEnabledOVOPay = phone !== "";

  const [showOVOSection, setShowOVOSection] = useState(false);

  const onSubmit = async () => {
    if (paymentOption === "ovo") {
      setShowOVOSection(true);

      window.scrollTo({ behavior: "smooth", top: 0 });
      utils.postMessageScrollTop();
    } else {
      submitPayment();
    }
  };

  const changeOtherPayment = () => {
    setShowOVOSection(false);

    window.scrollTo({ behavior: "smooth", top: 0 });
    utils.postMessageScrollTop();
  };

  const isValidPhone = () => {
    const validPhone = validator.isMobilePhone(phone);

    return validPhone;
  };

  const submitPaymentOvo = async () => {
    if (!isValidPhone()) {
      return toast.warning("Nomor HP Anda Tidak Valid");
    }

    let paymentType = "payment_gateaway";

    const bookResp = await bookReservationOvertime({
      reservationCode: reservationCode,
      formatCall: reservation.format_call,
      paymentChannel: paymentChannel,
      paymentOption: paymentOption,
      paymentType: paymentType,
      paymentPhone: phone,
    });
    const data = bookResp.data;
    setOvertimeData(bookResp.data);

    if (paymentChannel === "ewallet") {
      const redirect =
        data.payment_gateaway_data.raw_response.response.checkout_url;

      window.open(redirect, "_blank");
    }

    return navigate(`/booking/reservation/${reservationCode}`);
  };

  const submitPayment = async () => {
    let paymentType = "payment_gateaway";
    if (point > 0) {
      paymentType = "point";
    }

    const bookResp = await bookReservationOvertime({
      reservationCode: reservationCode,
      formatCall: reservation.format_call,
      paymentChannel: paymentChannel,
      paymentOption: paymentOption,
      paymentType: paymentType,
    });
    const data = bookResp.data;
    setOvertimeData(bookResp.data);

    if (paymentChannel === "ewallet") {
      const redirect =
        data.payment_gateaway_data.raw_response.response.checkout_url;

      window.open(redirect, "_blank");
    }

    return navigate(`/booking/reservation/${reservationCode}`);
  };

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGoogle, setIsLoadingGoogle] = useState(false);

  const onSignup = async () => {
    if (authType !== config.constant.signup) {
      return;
    }

    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }

    try {
      // Signup First
      const res = await authRegistration({
        email: email,
        password: password,
      });
      const token = res.data.token;

      setTokenAuth(token);

      const resProfile = await updateProfile({
        profile_url: "",
        username: name,
      });

      const data = resProfile.data;

      setNameCookies(data.username);
      setRoleCookies(data.role_name);
      setUserData({
        username: data.username,
        email: data.email,
        profile_url: data.profile_url,
        role_name: data.role_name,
      });

      window.location.reload();
    } catch (error) {
      toast.error(error as string);
    }
  };

  const onLogin = async () => {
    if (authType !== config.constant.login) {
      return;
    }

    if (!isValidAuth()) {
      return toast.warning("not valid input");
    }
    setIsLoading(true);

    try {
      // Login First
      const res = await authLogin({
        email: email,
        password: password,
      });
      const data = res.data;
      const token = data.token;

      if (data.role_name !== "member" && data.role_name !== "member_org") {
        toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
        window.scrollTo({ behavior: "smooth", top: 0 });
        utils.postMessageScrollTop();
      } else {
        await setTokenAuth(token);
        setNameCookies(data.username);
        setRoleCookies(data.role_name);
        setUserData({
          username: data.username,
          email: data.email,
          profile_url: data.profile_url,
          role_name: data.role_name,
        });

        setIsLoading(false);

        window.location.reload();
      }
    } catch (error) {
      toast.error(error as string);
    }
  };

  const onSubmitGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      loginGoogle("gmail", tokenResponse.access_token, userInfo.email);
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const loginGoogle = async (type: string, token: string, emailGoogle: any) => {
    setIsLoadingGoogle(true);
    authLoginSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
    })
      .then((res) => {
        setIsLoadingGoogle(false);
        const data = res.data;
        const token = data.token;
        if (data.role_name !== "member" && data.role_name !== "member_org") {
          toast.warning("Akun Spesialis Tidak Bisa Menggunakan Akun ini");
          window.scrollTo({ behavior: "smooth", top: 0 });
          utils.postMessageScrollTop();
        } else {
          setTokenAuth(token);
          setNameCookies(data.username);
          setRoleCookies(data.role_name);
          window.location.reload();
        }
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onRegisterGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      console.log(tokenResponse);
      const userInfo = await checkUserGoogle({
        token: tokenResponse.access_token,
      });

      console.log(userInfo);

      registerGoogle(
        "gmail",
        tokenResponse.access_token,
        userInfo.email,
        userInfo.name
      );
    },
    onError: () => {
      toast.warn("Gagal Masuk");
    },
  });

  const registerGoogle = async (
    type: string,
    token: string,
    emailGoogle: any,
    nameGoogle: any
  ) => {
    setIsLoadingGoogle(true);
    authRegistrationSosmed({
      email: emailGoogle,
      sosmed_type: type,
      sosmed_token: token,
      code: "",
    })
      .then((res) => {
        const data = res.data;
        const token = data.token;
        setTokenAuth(token);

        updateProfile({
          profile_url: "",
          username: nameGoogle,
        }).then((val) => {
          const updatedData = val.data;

          setNameCookies(updatedData.username);
          setRoleCookies(data.role_name);

          setUserData({
            member_code: updatedData.member_code,
            username: updatedData.username,
            email: updatedData.email,
            profile_url: updatedData.profile_url,
            role_name: updatedData.role_name,
          });

          setIsLoadingGoogle(false);

          queryProfile.refetch();
        });
      })
      .catch((err) => {
        setIsLoadingGoogle(false);
        toast.warn(CapitalizeFirstLetter(err));
      });
  };

  const onChangePayment = (channel: string, option: string) => {
    if (total === 0) {
      return toast.success("payment already used point");
    }

    setPaymentChannel(channel);
    setPaymentOption(option);
  };

  const onUsePoint = () => {
    if (point === 0) {
      if (total > latestPoint.latest_point) {
        setPoint(latestPoint.latest_point);
      } else {
        setPoint(total);

        setPaymentChannel("");
        setPaymentOption("");
      }
    } else {
      setPoint(0);
    }
  };

  const onCheckPromo = async () => {
    if (promoCode === "") {
      return toast.warn("please input promo code first");
    }

    try {
      const data = await checkPromo({
        amount: hourlyPrice,
        promoCode: promoCode,
      });

      setPromo(data.data.promo_applied);
    } catch (error) {}
  };

  if (queryReservation.isLoading || queryReservationOvertime.isLoading) {
    return <SkeletonPayment></SkeletonPayment>;
  }

  if (status === "waiting" && overtimeData)
    return (
      <WaitingPaymentOvertime
        overtime={overtimeData}
        reservation={reservation}
      />
    );

  return (
    <div>
      <div className="flex flex-col lg:flex-row">
        <div className="lg:basis-7/12">
          {showOVOSection ? (
            <div>
              <div className="md:hidden lg:basis-5/12 lg:pl-10">
                <BookingDetailSection />
              </div>

              <div className="md:p-8 my-4 md:my-0 md:bg-white lg:rounded-xl flex flex-col gap-4">
                <div className="w-full flex flex-col items-center justify-center gap-4">
                  <img src="/images/svg/ovo.svg" className="w-20" alt="ovo" />
                  <p>Masukkan nomor yang terdaftar di OVO</p>
                </div>
                <label
                  className="text-lg font-medium text-black-app"
                  htmlFor="phone"
                >
                  No HP*
                </label>
                <InputText
                  placeholder="Nomor yang terdaftar di OVO"
                  onChange={(val) => setPhone(val)}
                  defaultValue={phone}
                  type="tel"
                  id="phone"
                ></InputText>
                <ButtonBlue
                  className={`py-3 px-8 mt-4 ${
                    isEnabledOVOPay ? "" : "bg-neutral-200"
                  }`}
                  onClick={submitPaymentOvo}
                >
                  <p className="text-sm font-medium">Bayar Sekarang</p>
                </ButtonBlue>
                <ButtonBorder onClick={changeOtherPayment}>
                  Ganti Pembayaran Lain
                </ButtonBorder>
              </div>
            </div>
          ) : (
            <div className="flex flex-col lg:gap-8">
              {authType === config.constant.login ? (
                <LoginForm
                  email={email}
                  password={password}
                  isLoading={isLoading}
                  isLoadingGoogle={isLoadingGoogle}
                  isValidInput={isValidAuth()}
                  setEmail={setEmail}
                  setPassword={setPassword}
                  setAuthType={setAuthType}
                  onSubmit={onLogin}
                  onSubmitGoogle={onSubmitGoogle}
                ></LoginForm>
              ) : null}

              {authType === config.constant.loged ? (
                <LogedSection
                  onAuthTypeLogin={() => setAuthType(config.constant.login)}
                  profile={profile}
                ></LogedSection>
              ) : null}

              {authType === config.constant.signup ? (
                <RegisterForm
                  email={email}
                  name={name}
                  password={password}
                  isLoading={isLoading}
                  isLoadingGoogle={isLoadingGoogle}
                  isValidInput={isValidAuth()}
                  setAuthType={setAuthType}
                  setEmail={setEmail}
                  setName={setName}
                  setPassword={setPassword}
                  onSubmit={onSignup}
                  onSubmitGoogle={onRegisterGoogle}
                ></RegisterForm>
              ) : null}

              <div className="md:hidden lg:basis-5/12 lg:pl-10">
                <BookingDetailSection />
              </div>

              <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
                <div className="font-medium text-xl text-black-app">
                  Metode Pembayaran
                </div>
                <div>
                  <div className="font-semibold text-sm text-black-app">
                    E-Wallet
                  </div>
                  <div className="flex flex-col gap-3 my-4">
                    {ewallets.map((val) => {
                      return (
                        <ItemPayment
                          key={val.label}
                          imgSrc={val.imgSrc}
                          label={val.label}
                          isChecked={
                            paymentChannel === val.payment_channel &&
                            paymentOption === val.payment_option
                          }
                          onClick={() =>
                            onChangePayment(
                              val.payment_channel,
                              val.payment_option
                            )
                          }
                        ></ItemPayment>
                      );
                    })}
                  </div>

                  <div className="font-semibold text-sm text-black-app">
                    Bank Transfer
                  </div>
                  <div className="flex flex-col gap-3 my-4">
                    {transfers.map((val) => {
                      return (
                        <ItemPayment
                          key={val.label}
                          imgSrc={val.imgSrc}
                          label={val.label}
                          isChecked={
                            paymentChannel === val.payment_channel &&
                            paymentOption === val.payment_option
                          }
                          onClick={() =>
                            onChangePayment(
                              val.payment_channel,
                              val.payment_option
                            )
                          }
                        ></ItemPayment>
                      );
                    })}
                  </div>

                  <div className="font-semibold text-sm text-black-app mb-3">
                    QRIS
                  </div>
                  <ItemPayment
                    imgSrc="/images/payment/QRIS.png"
                    label="QRIS"
                    isChecked={paymentChannel === "qris"}
                    onClick={() => onChangePayment("qris", "dana")}
                  ></ItemPayment>

                  {/* {latestPoint ? (
                    <div className="cursor-pointer">
                      <div className="font-medium text-xl mt-8 text-black-app">
                        Maxi Points
                      </div>
                      <div
                        className="border rounded-md px-5 py-1 flex justify-between items-center my-3"
                        onClick={onUsePoint}
                      >
                        <div className="flex items-center">
                          <img
                            className="object-scale-down w-11 h-11"
                            src="/logo192.png"
                            alt=""
                          />
                          <div className="ml-4">
                            {latestPoint.latest_point ?? 0} Points
                          </div>
                        </div>
                        <div>
                          {point === 0 ? (
                            <div className="w-6 h-6 border rounded-full"></div>
                          ) : (
                            <img
                              className="w-6 h-6"
                              src={"/images/svg/check.svg"}
                              alt=""
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="font-medium text-xl text-black-app mt-5">
                    Promo
                  </div>
                  <div className="my-3 flex">
                    <InputText
                      onChange={(val) => setPromoCode(val)}
                      placeholder="Insert promo code"
                      value={promoCode}
                      type="text"
                    ></InputText>
                    <ButtonGold
                      className={`py-3 px-8 flex items-center ml-4 ${
                        isEnabledClaim ? "" : "bg-neutral-200"
                      }`}
                      onClick={onCheckPromo}
                    >
                      Klaim
                    </ButtonGold>
                  </div>
                   */}

                  <ButtonBlue
                    className={`py-3 px-8 mt-8 ${
                      isEnabledPay ? "" : "bg-neutral-200"
                    }`}
                    onClick={onSubmit}
                  >
                    <p className="text-lg font-medium">
                      {paymentOption === "ovo" ? "Masukkan No HP" : "Bayar"}
                    </p>
                  </ButtonBlue>
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="hidden md:block lg:basis-5/12 lg:pl-10">
          <BookingDetailSection />
        </div>
      </div>
    </div>
  );

  function BookingDetailSection() {
    return (
      <div className="p-8 bg-white lg:rounded-xl">
        <Title>Detail Pemesanan</Title>
        <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
          <div className="shrink-0">
            <img
              className="rounded-full w-16 h-16"
              src={`${config.AWS_URL_IMAGES}${reservation.psycholog_profile_url}`}
              alt=""
            />
          </div>
          <div className="flex flex-col gap-4">
            <div>
              <div className="text-xl font-medium">
                {reservation.psycholog_username}
              </div>
              <div className="text-neutral-secondary">
                {reservation.psycholog_title}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">
                Janji temu
              </div>
              <div className="text-neutral-800">
                Overtime {reservationOvertime.duration} minutes
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">Tanggal</div>
              <div className="text-neutral-800">
                {moment(reservation.date).format("MMMM D, YYYY")}
              </div>
            </div>
            <div>
              <div className="font-medium text-neutral-secondary">Waktu</div>
              <div className="text-neutral-800">
                {moment(reservation.time_from, "HH:mm").format("hh:mm A")}
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start mt-6">
          <div className="flex justify-between w-full">
            <div className="text-lg text-neutral-secondary">Subtotal</div>
            <div className="text-lg text-neutral-primary">
              {utils.currencyFormatter(hourlyPrice)}
            </div>
          </div>
          <div className="flex justify-between w-full">
            <div className="text-lg text-neutral-secondary">Promo</div>
            <div className="text-lg text-neutral-primary">
              {promo > 0 ? `-${utils.currencyFormatter(promo)}` : "Rp0"}
            </div>
          </div>
        </div>

        <div className="flex justify-between text-xl font-medium text-black-app mt-6">
          <div>Total</div>
          <div>{total > 0 ? `${utils.currencyFormatter(total)}` : "Rp0"}</div>
        </div>

        <div className="mt-8 flex gap-5">
          <div>
            <ShieldTick variant="Bold" className="text-brand-dark"></ShieldTick>
          </div>
          <div className="text-neutral-secondary">
            Your consultation experience is guaranteed with our{" "}
            <ButtonModalPolicy></ButtonModalPolicy>
          </div>
        </div>
      </div>
    );
  }
}

function LogedSection(props: { profile: any; onAuthTypeLogin: () => void }) {
  return (
    <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
      <div className="font-medium text-xl text-black-app">
        Hi, {props.profile.username}
      </div>
      <div>You are logged in as {props.profile.email}</div>
      <div>
        If this is not your account.{" "}
        <span
          className="text-brand-dark cursor-pointer"
          onClick={props.onAuthTypeLogin}
        >
          Switch Account
        </span>
      </div>
    </div>
  );
}

function ItemPayment(props: {
  label: string;
  imgSrc: string;
  isChecked: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className="border rounded-md px-5 py-4 flex justify-between cursor-pointer"
      onClick={props.onClick}
    >
      <div className="flex">
        <img className="object-scale-down w-28 h-6" src={props.imgSrc} alt="" />
        <div>{props.label}</div>
      </div>
      <div>
        {props.isChecked ? (
          <img className="w-6 h-6" src="/images/svg/check.svg" alt="" />
        ) : (
          <div className="w-6 h-6 border rounded-full"></div>
        )}
      </div>
    </div>
  );
}
