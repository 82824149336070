import { ButtonBlue } from "components/Button";
import { InputText } from "components/Input";
import { config } from "helper/config";
import utils from "utils";

export function RegisterForm(props: {
  email: string;
  password: string;
  name: string;
  // phone: string;
  isLoading: boolean;
  isLoadingGoogle: boolean;
  isValidInput: boolean;
  setEmail: (_: string) => void;
  setPassword: (_: string) => void;
  setName: (_: string) => void;
  // setPhone: (_: string) => void;
  setAuthType: (_: string) => void;
  onSubmit: () => void;
  onSubmitGoogle: () => void;
}) {
  return (
    <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
      <div className="font-medium text-xl text-black-app">
        {utils.getLangByKey("auth_register_title")}
      </div>
      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="name">
            {utils.getLangByKey("auth_register_name_title")}*
          </label>
        </div>
        <InputText
          placeholder={utils.getLangByKey("auth_register_name_title")}
          onChange={(val) => props.setName(val)}
          defaultValue={props.name}
          type="text"
          id="name"
        ></InputText>
      </div>
      {/* <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="phone">
            Phone Number*
          </label>
        </div>
        <InputText
          placeholder="Your phone number"
          onChange={(val) => props.setPhone(val)}
          defaultValue={props.phone}
          type="tel"
          id="phone"
        ></InputText>
      </div> */}
      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="email">
            {utils.getLangByKey("global_email")}*
          </label>
        </div>
        <InputText
          placeholder={utils.getLangByKey("global_email")}
          onChange={(val) => props.setEmail(val)}
          defaultValue={props.email}
          type="email"
          id="email"
        ></InputText>
      </div>
      <div>
        <div className="mb-2">
          <label
            className="text-lg font-medium text-black-app"
            htmlFor="password"
          >
            {utils.getLangByKey("global_password")}*
          </label>
        </div>
        <InputText
          placeholder={utils.getLangByKey("global_password")}
          onChange={(val) => props.setPassword(val)}
          defaultValue={props.password}
          type="password"
          id="password"
        ></InputText>
      </div>
      <div className="flex flex-row	items-center">
        <div className="flex-1 h-px bg-custom-border" />
        <div>
          <p className="w-auto px-4 text-base text-gray-400">
            {utils.getLangByKey("global_or")}
          </p>
        </div>
        <div className="flex-1 h-px bg-custom-border" />
      </div>
      <button
        type="button"
        className="text-white px-4 flex items-center justify-center bg-white border border-solid border-custom-border hover:bg-blue/10 rounded-full w-full h-12 mt-2"
        onClick={props.onSubmitGoogle}
      >
        {props.isLoadingGoogle ? (
          <div
            className="h-5 w-5 animate-spin rounded-full border-4 border-solid border-blue border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          ></div>
        ) : (
          <img
            className="h-[24px] w-[24px] cursor-pointer mr-4"
            src="/images/svg/ic_google.svg"
            alt=""
          />
        )}
        <span className="text-black font-medium">
          {props.isLoadingGoogle
            ? utils.getLangByKey("global_please_wait")
            : utils.getLangByKey("auth_register_with_google")}
        </span>
      </button>

      <div className="font-medium mt-8">
        {utils.getLangByKey("auth_register_have_account")}{" "}
        <span
          className="text-brand-dark cursor-pointer"
          onClick={() => props.setAuthType(config.constant.login)}
        >
          {utils.getLangByKey("global_login")}
        </span>
      </div>

      <ButtonBlue
        className={`px-6 py-2 ${props.isValidInput ? "" : "bg-slate-300"}`}
        onClick={props.onSubmit}
      >
        {utils.getLangByKey("global_register")}
      </ButtonBlue>
    </div>
  );
}
