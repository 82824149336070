import { ButtonBlack, ButtonBorder } from "components/Button";
import { Title } from "components/Text";
import { ButtonModalPolicy } from "components/Modal";
import Stepper from "components/stepper";
import { config } from "helper/config";
import { ArrowDown2, ArrowUp2, Copy, ShieldTick } from "iconsax-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import utils from "utils";
import dayjs from "dayjs";
import "dayjs/locale/id";
import "dayjs/locale/en";

const VIDEO_CALL = "video_call";
const VOICE_CALL = "voice_call";

export default function WaitingPayment(props: {
  onCancelPayment: () => void;
  onChangePayment: () => void;
  reservation: any;
}) {
  const reservation = props.reservation;

  const gatewaydata = reservation.payment_gateaway_data;
  const paymentMethod = gatewaydata.method;
  const paymentResponseType = gatewaydata.raw_response.type;
  const expiredDate = gatewaydata.raw_response.response.expiration_time;

  const isVA = () => paymentResponseType === "VA";
  const isQRIS = () => paymentResponseType === "QRIS";
  //   const isEWallet = () => paymentResponseType === "EWALLET";

  const paymentType = isVA()
    ? "Virtual Account"
    : isQRIS()
    ? "QRIS"
    : "E-Wallet";

  let checkoutURL = "";
  let qrString: string = "";
  let accountNumber: any;
  let instructionATM: any = null;
  let instructionMobileApp: any = null;
  if (isVA()) {
    const instruction =
      reservation.payment_gateaway_data.raw_response.response
        .payment_instruction;

    if (utils.getLangActive() === "EN") {
      if (instruction.en) {
        instructionATM = instruction.en.atm;
        instructionMobileApp = instruction.en.mobile_app;
      }
    } else {
      if (instruction.id) {
        instructionATM = instruction.id.atm;
        instructionMobileApp = instruction.id.mobile_app;
      }
    }

    accountNumber = gatewaydata.raw_response.response.account_number;
    checkoutURL = gatewaydata.raw_response.response.checkout_url;
  }

  if (isQRIS()) {
    qrString = gatewaydata.raw_response.response.qr_string;
  }

  const onCopy = () => {
    if (isVA()) {
      navigator.clipboard.writeText(accountNumber);
      return toast.success("bank number already copied");
    }
  };

  return (
    <div>
      <Stepper step={3}></Stepper>

      <div className="flex flex-col lg:flex-row">
        <div className="lg:basis-7/12 flex flex-col lg:gap-12 mt-4 md:mt-0">
          <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
            <div className="font-medium text-2xl text-black-app text-center">
              {utils.getLangByKey("payment_waiting_title")}
            </div>
            <CountDown date={new Date(expiredDate)}></CountDown>
            <div className="border px-6 rounded-lg">
              <div className="flex flex-col justify-between border-b py-6 gap-2 md:flex-row">
                <div className="text-neutral-secondary font-medium">
                  {utils.getLangByKey("global_payment_method")}
                </div>
                <div>
                  {isQRIS()
                    ? `${paymentMethod}`
                    : `${paymentMethod} ${paymentType}`}
                </div>
              </div>

              {isQRIS() === false ? (
                <div className="flex flex-col justify-between border-b py-6 gap-2 md:flex-row">
                  <div className="text-neutral-secondary font-medium">
                    {`${isVA() ? `${paymentType} Number` : "Link"}`}{" "}
                  </div>
                  <div className="flex gap-2 cursor-pointer" onClick={onCopy}>
                    {isVA() ? (
                      <>
                        <div>{accountNumber}</div>
                        <div className="bg-blue-background p-1 rounded-full">
                          <Copy variant="Bold" size={16}></Copy>
                        </div>
                      </>
                    ) : (
                      <a
                        className="text-brand-dark"
                        href={checkoutURL}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {utils.getLangByKey("global_payment_link")}
                      </a>
                    )}
                  </div>
                </div>
              ) : null}

              <div className="flex flex-col justify-between py-6 gap-2 md:flex-row">
                <div className="text-neutral-secondary font-medium">
                  {utils.getLangByKey("global_payment_total")}
                </div>
                <div>
                  {utils.currencyFormatter(
                    reservation.payment_gateaway_data.amount
                  )}
                </div>
              </div>
            </div>
            <div className="text-center text-lg">
              <div className="text-neutral-secondary">
                {utils.getLangByKey("payment_waiting_already")}
              </div>
              <div
                className="text-brand-dark font-medium cursor-pointer"
                onClick={() => window.location.reload()}
              >
                {utils.getLangByKey("payment_waiting_update")}
              </div>
            </div>

            {isQRIS() ? (
              <div className="mx-auto">
                <img className="w-64 md:w-80 lg:w-96" src={qrString} alt="" />
              </div>
            ) : null}

            {isQRIS() ? (
              <>
                <div className="font-medium text-xl text-black-app">
                  {utils.getLangByKey("global_payment_instructions")}
                </div>
                <ol className="list-decimal ml-4">
                  <li>{utils.getLangByKey("payment_instruction_qris_1")}</li>
                  <li>{utils.getLangByKey("payment_instruction_qris_2")}</li>
                  <li>
                    {utils.getLangByKey("payment_instruction_qris_3")}{" "}
                    <span className="font-semibold">
                      {utils.getLangByKey("global_pay")}
                    </span>
                  </li>
                  <li>{utils.getLangByKey("payment_instruction_qris_4")}</li>
                </ol>
              </>
            ) : null}

            {isVA() && instructionATM && instructionMobileApp ? (
              <>
                <div className="font-medium text-xl text-black-app">
                  {utils.getLangByKey("global_payment_instructions")}
                </div>
                <Instructions
                  heading={instructionATM.heading}
                  content={instructionATM.instruction_text}
                ></Instructions>
                <Instructions
                  heading={instructionMobileApp.heading}
                  content={instructionMobileApp.instruction_text}
                ></Instructions>
              </>
            ) : null}

            <div className="flex justify-end gap-4">
              <div onClick={props.onCancelPayment}>
                <ButtonBorder>
                  <div className="font-medium">
                    {utils.getLangByKey("payment_cancel")}
                  </div>
                </ButtonBorder>
              </div>
              <ButtonBlack
                className="flex items-center px-5 font-medium justify-center text-center"
                onClick={props.onChangePayment}
              >
                {utils.getLangByKey("payment_change")}
              </ButtonBlack>
            </div>
          </div>
        </div>

        <div className="order-first lg:order-last lg:basis-5/12 lg:pl-10">
          <div className="p-8 bg-white lg:rounded-xl">
            <Title>{utils.getLangByKey("booking_detail_title")}</Title>
            <Link to={`/booking/${reservation.psycholog_code}`}>
              <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
                <div className="shrink-0">
                  <img
                    className="rounded-full w-16 h-16 object-cover object-top"
                    src={`${config.AWS_URL_IMAGES}${reservation.psycholog_profile_url}`}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <div>
                    <div className="text-xl font-medium">
                      {reservation.psycholog_username}
                    </div>
                    <div className="text-neutral-secondary">
                      {reservation.specialist_title}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      {utils.getLangByKey("global_appointment")}
                    </div>
                    <div className="text-neutral-800">
                      {reservation.format_call === VIDEO_CALL
                        ? utils.getLangByKey("global_video_call") + " 60 "
                        : reservation.format_call === VOICE_CALL
                        ? utils.getLangByKey("global_voice_call") + " 60 "
                        : utils.getLangByKey("global_preview") + " 15 "}
                      {utils.getLangByKey("global_minutes")}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      {utils.getLangByKey("global_date")}
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.date).format("MMMM D, YYYY")}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      {utils.getLangByKey("global_time")}
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.time_from, "HH:mm").format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            </Link>

            <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start mt-6">
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Subtotal</div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.amount)}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">
                  {utils.getLangByKey("booking_point_maxi")}
                </div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.point_used)}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Promo</div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.promo_used)}
                </div>
              </div>
            </div>

            <div className="flex justify-between text-xl font-medium text-black-app mt-6">
              <div>Total</div>
              <div>
                {utils.currencyFormatter(
                  reservation.payment_gateaway_data.amount
                )}
              </div>
            </div>

            <div className="mt-8 flex gap-5">
              <div>
                <ShieldTick
                  variant="Bold"
                  className="text-brand-dark"
                ></ShieldTick>
              </div>
              <div className="text-neutral-secondary">
                {utils.getLangByKey("booking_detail_policy")}{" "}
                <ButtonModalPolicy></ButtonModalPolicy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function CountDown(props: { date: Date }) {
  const [miliseconds, setMiliseconds] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      const milis = props.date.getTime() - new Date().getTime();

      if (milis < 0) {
        clearInterval(interval);
      } else {
        setMiliseconds(milis);
      }
    }, 500);

    return () => clearInterval(interval);
  }, [props.date]);

  const seconds = Math.floor(miliseconds / 1000);
  const minutes = Math.floor(seconds / 60);
  const hour = Math.floor(minutes / 60);
  const secondsInTime = seconds % 60;
  const minutesInTime = minutes % 60;

  const ItemTimer = (props: { value: number }) => {
    return (
      <div
        className={`text-brand-dark rounded-md w-24 h-24 flex items-center justify-center
                    ${miliseconds > 0 ? "bg-blue-background" : "bg-red-app/20"}
                `}
      >
        <div>{utils.timeZeroLeading(props.value)}</div>
      </div>
    );
  };

  return (
    <>
      <div className="flex text-4xl justify-center items-center">
        {hour > 0 ? (
          <>
            <ItemTimer value={hour}></ItemTimer>
            <div className="font-medium mx-2">:</div>
          </>
        ) : null}

        <ItemTimer value={minutesInTime}></ItemTimer>
        <div className="font-medium mx-2">:</div>
        <ItemTimer value={secondsInTime}></ItemTimer>
      </div>
      <div className="font-medium text-lg text-center">
        {utils.getLangByKey("global_before")}{" "}
        {dayjs(props.date)
          .locale(utils.getLangActive().toLowerCase())
          .format("dddd, D MMMM YYYY HH:mm Z")}
      </div>
    </>
  );
}

function Instructions(props: { heading: string; content: string }) {
  const [isOpen, setOpen] = useState(false);

  return (
    <div className="overflow-hidden border rounded-md">
      <div
        className="flex justify-between items-center p-4 cursor-pointer"
        onClick={() => setOpen(!isOpen)}
      >
        <div className="font-medium">{props.heading}</div>
        <div>
          {isOpen ? (
            <ArrowUp2 size={20} variant="Linear"></ArrowUp2>
          ) : (
            <ArrowDown2 size={20} variant="Linear"></ArrowDown2>
          )}
        </div>
      </div>

      <div className={`overflow-hidden ${isOpen ? "" : "h-0"}`}>
        <div className="pb-4 reset-style ">
          <div dangerouslySetInnerHTML={{ __html: props.content }}></div>
        </div>
      </div>
    </div>
  );
}
