import api from "./api";
import utils from "utils";

export const updateReservation = (params: {
  reservationCode: string;
  roomLink: string;
  eventId: string;
}) => {
  return api
    .put(`/v1/members/specialist/reservation/${params.reservationCode}`, {
      room_link: params.roomLink,
      event_id: params.eventId,
    })
    .then((val) => val.data);
};

export const getReservation = (params: { reservationCode: string }) => {
  return api
    .get(`/v1/members/specialist/reservation/${params.reservationCode}`, {
      params: {
        lang: utils.getLangActive().toLowerCase(),
      },
    })
    .then((val) => val.data);
};

export const bookReservation = (params: {
  specialistCode: string;
  scheduleDetailCode: string;
  formatCall: string;
  paymentType: string;
  paymentChannel: string;
  paymentOption: string;
  paymentPhone?: string;
  promoCode?: string;
  promoApplied?: number;
}) => {
  return api
    .post(
      `/v1/members/specialist/${params.specialistCode}/schedule/${params.scheduleDetailCode}/reservation`,
      {
        format_call: params.formatCall,
        payment_type: params.paymentType,
        payment_channel: params.paymentChannel,
        payment_option: params.paymentOption,
        payment_phone: params.paymentPhone,
        promo_code: params.promoCode,
        promo_applied: params.promoApplied,
      }
    )
    .then((val) => val.data);
};

export const fetchCounseling = (params: { type: string }) => {
  return api
    .get("/v1/members/counseling", {
      params: {
        type: params.type,
        is_active: true,
        lang: utils.getLangActive().toLowerCase(),
        limit: 100,
      },
    })
    .then((val) => val.data);
};

export const createCounselingAnswer = (reservationCode: string, data: any) => {
  return api
    .post(
      `/v1/members/specialist/reservation/${reservationCode}/counseling`,
      data
    )
    .then((val) => val.data);
};

export const getCounselingAnswer = (reservationCode: string) => {
  return api
    .get(
      `/v1/members/specialist/reservation/${reservationCode}/counseling/answer`,
      {
        params: {
          lang: utils.getLangActive().toLowerCase(),
        },
      }
    )
    .then((val) => val.data);
};
