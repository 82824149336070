import {
  Checkbox,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { ButtonBlack } from "components/Button";
import { InputArea, InputSelect } from "components/Input";
import { Loading } from "components/Loading";
import { Title } from "components/Text";
import Stepper from "components/stepper";
import { config } from "helper/config";
import { CalendarTick, Copy, TickCircle } from "iconsax-react";
import moment from "moment";
import { useState } from "react";
import ApiCalendar from "react-google-calendar-api";
import { useQuery } from "react-query";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { fetchCounseling, updateReservation } from "service/reservation";
import utils from "utils";

const VIDEO_CALL = "video_call";
const VOICE_CALL = "voice_call";
const apiCalendar = new ApiCalendar(config.calendar);

export default function ConfirmedPayment(props: {
  reservation: any;
  counselingAnswer: any;
  onCreateCounselingAnswer: (_: any) => void;
}) {
  const reservation = props.reservation;
  const total =
    (reservation.amount ?? 0) - reservation.point_used - reservation.promo_used;

  const createEvent = async () => {
    var _start = moment(reservation.date + " " + reservation.time_from).format(
      "yyyy-MM-DD HH:mm"
    );
    var _end =
      reservation.format_call === "preview"
        ? moment(
            reservation.date +
              " " +
              reservation.time_from.substring(0, 2) +
              ":15"
          ).format("yyyy-MM-DD HH:mm")
        : moment(reservation.date + " " + reservation.time_to).format(
            "yyyy-MM-DD HH:mm"
          );
    const events = {
      summary:
        reservation.format_call === "preview"
          ? "Sesi konsultasi 15 menit"
          : "Sesi konsultasi 60 menit",
      guestsCanSeeOtherGuests: false,
      guestsCanModify: false,
      guestsCanInviteOthers: false,
      start: {
        dateTime: moment(_start).format(),
        timeZone: reservation.member_timezone.title,
      },
      end: {
        dateTime: moment(_end).format(),
        timeZone: reservation.member_timezone.title,
      },
      attendees: [
        {
          email: reservation.member_email,
          responseStatus: "needsAction",
          displayName: reservation.member_username,
        },
        {
          email: reservation.psycholog_email,
          responseStatus: "needsAction",
          displayName: reservation.psycholog_username,
        },
      ],
      conferenceData: {
        createRequest: {
          conferenceSolutionKey: { type: "hangoutsMeet" },
          requestId: reservation.reservation_code,
        },
      },
      visibility: "private",
    };

    try {
      const result = await apiCalendar.createEventWithVideoConference(
        events,
        "primary",
        "all"
      );
      const data = result.result;
      await updateReservation({
        reservationCode: reservation.reservation_code,
        roomLink: data.conferenceData.entryPoints[0].uri,
        eventId: data.id,
      });
      toast.success("Success Add To Calendar");
      window.location.reload();
    } catch (error) {
      toast.error("Failed Add To Calendar");
      console.log(error);
    }
  };

  const onCalendar = () => {
    if (reservation.room_link !== "") {
      return toast.warning("gmeet link already created!");
    }

    apiCalendar
      .handleAuthClick()
      .then((result: any) => {
        createEvent();
      })
      .catch((error: any) => {
        //
      });
  };

  const onCopy = () => {
    window.navigator.clipboard.writeText(reservation.room_link);
    toast.success("gmeet link already copied!");
  };

  return (
    <div>
      <Stepper step={3}></Stepper>
      <div className="max-w-2xl mx-auto">
        <div className="p-8 bg-white rounded-xl">
          <div className="max-w-lg mx-auto">
            <div className="text-center">
              <Title>{utils.getLangByKey("payment_complete_title")}</Title>
            </div>
            <div className="flex justify-center my-8">
              <div className="bg-blue-background p-6 rounded-full">
                <TickCircle
                  className="text-brand-dark"
                  variant="Bold"
                  size={46}
                ></TickCircle>
              </div>
            </div>
            <div className="flex flex-col items-center text-lg mb-8">
              <div>{utils.getLangByKey("payment_complete_subtitle")}</div>
              <div>
                <span className="font-medium">{reservation.member_email}</span>{" "}
                {utils.getLangByKey("payment_complete_subtitle_2")}
              </div>
            </div>
            <Title>{utils.getLangByKey("booking_detail_title")}</Title>
            <Link to={`/booking/${reservation.psycholog_code}`}>
              <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:flex-row">
                <div className="shrink-0">
                  <img
                    className="rounded-full w-16 h-16 object-cover object-top"
                    src={`${config.AWS_URL_IMAGES}${reservation.psycholog_profile_url}`}
                    alt=""
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <div>
                    <div className="text-xl font-medium mb-1">
                      {reservation.psycholog_username}
                    </div>
                    <div className="text-neutral-secondary">
                      {reservation.psycholog_title}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      {utils.getLangByKey("global_appointment")}
                    </div>
                    <div className="text-neutral-800">
                      {reservation.format_call === VIDEO_CALL
                        ? utils.getLangByKey("global_video_call") + " 60 "
                        : reservation.format_call === VOICE_CALL
                        ? utils.getLangByKey("global_voice_call") + " 60 "
                        : utils.getLangByKey("global_preview") + " 15 "}
                      {utils.getLangByKey("global_minutes")}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      {utils.getLangByKey("global_date")}
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.date).format("MMMM D, YYYY")}
                    </div>
                  </div>
                  <div>
                    <div className="font-medium text-neutral-secondary">
                      {utils.getLangByKey("global_time")}
                    </div>
                    <div className="text-neutral-800">
                      {moment(reservation.time_from, "HH:mm").format("hh:mm A")}
                    </div>
                  </div>
                </div>
              </div>
            </Link>

            <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start mt-6">
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Subtotal</div>
                <div className="text-lg text-neutral-primary">
                  {utils.currencyFormatter(reservation.amount)}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">
                  {utils.getLangByKey("booking_point_maxi")}
                </div>
                <div className="text-lg text-neutral-primary">
                  - {utils.currencyFormatter(reservation.point_used)}
                </div>
              </div>
              <div className="flex justify-between w-full">
                <div className="text-lg text-neutral-secondary">Promo</div>
                <div className="text-lg text-neutral-primary">
                  - {utils.currencyFormatter(reservation.promo_used)}
                </div>
              </div>
            </div>

            <div className="flex justify-between text-xl font-medium text-black-app mt-6">
              <div>Total</div>
              <div>{utils.currencyFormatter(total)}</div>
            </div>
          </div>
        </div>

        {/* Add To Calendar */}
        {!reservation.room_link ? (
          <div className="p-8 bg-white rounded-xl mt-12">
            <div className="font-medium text-xl text-center">
              {utils.getLangByKey("payment_complete_gcal_title")}
            </div>
            <div className="my-10 w-80 mx-auto">
              <div className="flex items-start justify-center gap-4">
                <div className="rounded-full p-5 bg-blue-background shrink-0 text-brand-dark flex justify-center items-center text-lg font-medium">
                  <div>
                    <CalendarTick variant="Bold" size={56}></CalendarTick>
                  </div>
                </div>
              </div>
              <div className="text-center mt-2 text-">
                {utils.getLangByKey("payment_complete_gcal_subtitle_1")}{" "}
                <span className="font-semibold">
                  {utils.getLangByKey("payment_complete_gcal_subtitle_2")}
                </span>{" "}
                {utils.getLangByKey("payment_complete_gcal_subtitle_3")}
              </div>
            </div>
            <ButtonBlack
              className="max-w-xs mx-auto text-center py-4"
              onClick={onCalendar}
            >
              {utils.getLangByKey("payment_complete_gcal_add")}
            </ButtonBlack>
          </div>
        ) : (
          <div className="p-8 bg-white rounded-xl mt-12">
            <div className="font-medium text-xl text-center">
              {utils.getLangByKey("payment_complete_gcal_done_title")}
            </div>
            <div className="mt-10 mx-auto">
              <div className="flex items-start justify-center gap-4">
                <div className="rounded-full p-5 bg-blue-background shrink-0 text-brand-dark flex justify-center items-center text-lg font-medium">
                  <div>
                    <CalendarTick variant="Bold" size={56}></CalendarTick>
                  </div>
                </div>
              </div>
              <div className="text-center mt-2 text-lg">
                {utils.getLangByKey("payment_complete_gcal_done_subtitle")}
              </div>
              <div
                className="flex justify-center items-center mt-2 gap-2 cursor-pointer"
                onClick={onCopy}
              >
                <div className="text-brand-dark text-lg font-medium">
                  {reservation.room_link.substring(8)}
                </div>
                <div className="bg-neutral-background p-2 rounded-full">
                  <Copy variant="Bold"></Copy>
                </div>
              </div>
            </div>
          </div>
        )}

        {/* Fill Pre-Counseling Form */}
        {props.counselingAnswer ? (
          <PreCounselingAnswer
            values={props.counselingAnswer}
          ></PreCounselingAnswer>
        ) : (
          <PreCounseling
            onSubmit={props.onCreateCounselingAnswer}
          ></PreCounseling>
        )}

        <div className="font-medium text-xl text-center my-8">
          {utils.getLangByKey("payment_complete_download_title")}
        </div>
        <div className="flex flex-col">
          <div className="flex flex-col justify-between gap-8 text-neutral-secondary order-first lg:flex-row">
            <div className="flex gap-5">
              <div>
                <TickCircle
                  className="text-black"
                  size={28}
                  variant="Bold"
                ></TickCircle>
              </div>
              <div>
                {utils.getLangByKey("payment_complete_download_point_1_1")}{" "}
                <span className="text-brand-dark font-medium">
                  {utils.getLangByKey("payment_complete_download_point_1_2")}
                </span>{" "}
                {utils.getLangByKey("payment_complete_download_point_1_3")}
              </div>
            </div>
            <div className="flex gap-5">
              <div>
                <TickCircle
                  className="text-black"
                  size={28}
                  variant="Bold"
                ></TickCircle>
              </div>
              <div>
                {utils.getLangByKey("payment_complete_download_point_2")}
              </div>
            </div>
            <div className="flex gap-5">
              <div>
                <TickCircle
                  className="text-black"
                  size={28}
                  variant="Bold"
                ></TickCircle>
              </div>
              <div>
                {utils.getLangByKey("payment_complete_download_point_3")}
              </div>
            </div>
          </div>
          <div className="my-8">
            <img
              className="w-60 mx-auto object-cover"
              src="/images/reservation/showcase.png"
              alt=""
            />
            <div className="flex justify-center gap-6 my-8">
              <a href={config.playstoreLink} target="_blank" rel="noreferrer">
                <img className="w-44" src="/images/logo/playstore.png" alt="" />
              </a>
              <a href={config.appstoreLink} target="_blank" rel="noreferrer">
                <img className="w-44" src="/images/logo/appstore.png" alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function PreCounseling(props: { onSubmit?: (_: any) => void; value?: any }) {
  const [answer, setAnswer] = useState<{ [key: string]: any }>({});

  const onFetchCounseling = () => {
    return () =>
      fetchCounseling({ type: "pre_counseling" }).then((val) => val.data);
  };

  const queryCounseling = useQuery("fetchCounseling", onFetchCounseling());
  const counseling = queryCounseling.data;

  if (queryCounseling.isLoading) {
    return <Loading></Loading>;
  }

  const onChangeAnswer = (id: number, newValue: any) => {
    setAnswer({
      ...answer,
      [id]: newValue,
    });
  };

  const isEnabled = () => {
    if (Object.keys(answer).length < counseling.length) {
      return false;
    }

    for (const key in answer) {
      if (Object.prototype.hasOwnProperty.call(answer, key)) {
        const val = answer[key];
        if (val === "") {
          return false;
        }
      }
    }

    return true;
  };

  const onSubmit = () => {
    if (props.onSubmit) {
      const values: any[] = [];

      for (const key in answer) {
        if (Object.prototype.hasOwnProperty.call(answer, key)) {
          let item = answer[key];
          if (!(typeof item === "string")) {
            item = item.join(",");
          }

          values.push({
            counseling_id: Number(key),
            type: "pre_counseling",
            answer: item,
          });
        }
      }

      setAnswer({});
      props.onSubmit(values);
    }
  };

  return (
    <div className="p-8 bg-white rounded-xl mt-6 mb-16">
      <div className="max-w-lg mx-auto">
        <div className="text-xl font-medium text-center mb-8">
          {utils.getLangByKey("payment_complete_precouns_header")}
        </div>
        <div className="text-xl font-medium text-center mb-8">
          {utils.getLangByKey("payment_complete_precouns_title")}
        </div>
        <div className="flex flex-col gap-4">
          {(counseling ?? []).map((item: any) => {
            const value = answer[item.id] ?? "";

            if (item.answer_type === "select") {
              return (
                <div key={item.counseling_code}>
                  <InputSelect
                    title={item.title}
                    defaultValue={value}
                    onChange={(val) => onChangeAnswer(item.id, val)}
                  >
                    <option value="" disabled>
                      {item.hint}
                    </option>
                    {item.answer.map((val: any) => {
                      return (
                        <option value={val.title} key={val.order}>
                          {val.title}
                        </option>
                      );
                    })}
                  </InputSelect>
                </div>
              );
            }

            if (item.answer_type === "multiselect") {
              let valArr: any[] = [];
              if (typeof value === "string") {
                valArr = [];
              } else {
                valArr = value;
              }

              return (
                <div key={item.counseling_code}>
                  <label
                    htmlFor={item.id.toString()}
                    className="block mb-2 text-sm font-medium text-gray-900"
                  >
                    {item.hint}
                  </label>
                  <Select
                    id={item.id.toString()}
                    multiple
                    value={valArr}
                    onChange={(val) => {
                      let valueArr = [];
                      if (typeof val.target.value === "string") {
                        valueArr = [val.target.value];
                      } else {
                        valueArr = val.target.value;

                        if (valueArr[0] === "") {
                          valueArr.splice(0, 1);
                        }
                      }

                      onChangeAnswer(item.id, valueArr);
                    }}
                    input={
                      <OutlinedInput placeholder={item.hint} className="py-0" />
                    }
                    renderValue={(selected) => selected.join(", ")}
                    className="bg-slate-50 rounded-lg w-full"
                  >
                    {item.answer.map((val: any, i: number) => {
                      return (
                        <MenuItem key={val.order} value={val.title}>
                          <Checkbox checked={valArr.indexOf(val.title) > -1} />
                          <ListItemText primary={val.title} />
                        </MenuItem>
                      );
                    })}
                  </Select>
                </div>
              );
            }

            if (item.answer_type === "textarea") {
              return (
                <div key={item.counseling_code}>
                  <InputArea
                    title={item.title}
                    placeholder={item.hint}
                    defaultValue={value}
                    onChange={(val) => onChangeAnswer(item.id, val)}
                  ></InputArea>
                </div>
              );
            }

            return <div key={item.counseling_code}></div>;
          })}
          <div className="flex justify-center mt-6">
            <ButtonBlack
              className={`${isEnabled() ? "" : "!bg-neutral-secondary"}`}
              onClick={onSubmit}
            >
              <div className="py-3 px-24">
                {utils.getLangByKey("global_submit")}
              </div>
            </ButtonBlack>
          </div>
        </div>
      </div>
    </div>
  );
}

function PreCounselingAnswer(props: { values: any[] }) {
  return (
    <div className="p-8 bg-white rounded-xl mt-6 mb-16">
      <div className="max-w-lg mx-auto">
        <div className="text-xl font-medium text-center mb-8">
          {utils.getLangByKey("payment_complete_precouns_answer")}
        </div>
        <div className="relative overflow-x-auto">
          <table className="text-sm text-left text-gray-500">
            <tbody>
              {props.values.map((val, index) => {
                return (
                  <tr className="bg-white border-b" key={index}>
                    <th className="py-4 font-medium text-gray-900">
                      {val.title}
                    </th>
                    <td className="px-4">:</td>
                    <td className="py-4">{val.answer}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}
