export const ewallets = [
	{
		label: "GoPay",
		imgSrc: "/images/payment/Gopay.png",
		payment_channel: "ewallet",
		payment_option: "gopay",
	},
	{
		label: "OVO Cash",
		imgSrc: "/images/payment/OVO.png",
		payment_channel: "ewallet",
		payment_option: "ovo",
	},
	{
		label: "ShopeePay",
		imgSrc: "/images/payment/Shopeepay.png",
		payment_channel: "ewallet",
		payment_option: "shopeepay",
	},
	{
		label: "Dana",
		imgSrc: "/images/payment/Dana.png",
		payment_channel: "ewallet",
		payment_option: "dana",
	},
];

export const transfers = [
	{
		label: "BCA",
		imgSrc: "/images/payment/BCA.png",
		payment_channel: "va",
		payment_option: "bca",
	},
	{
		label: "BRI",
		imgSrc: "/images/payment/BRI.png",
		payment_channel: "va",
		payment_option: "bri",
	},
	{
		label: "BNI",
		imgSrc: "/images/payment/BNI.png",
		payment_channel: "va",
		payment_option: "bni",
	},
	{
		label: "Mandiri",
		imgSrc: "/images/payment/Mandiri.png",
		payment_channel: "va",
		payment_option: "mandiri",
	},
];
