import api from "./api";
import utils from "utils";

export const getSpecialist = async (params: { memberCode: string }) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}`, {
    params: {
      lang: utils.getLangActive().toLowerCase(),
    },
  });

  return resp.data;
};

export const getSpecialistRates = async (params: { memberCode: string }) => {
  return api
    .get(`/v2/specialist/${params.memberCode}/rates`)
    .then((val) => val.data);
};

export const getSpecialistReview = async (params: {
  memberCode: string;
  page: number;
  limit: number;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}/review`, {
    params: {
      lang: utils.getLangActive().toLowerCase(),
      page: params.page,
      limit: params.limit,
    },
  });

  return resp.data;
};

export const getSpecialists = (params: {
  page: number;
  limit: number;
  status?: string;
  keyword?: string;
  membership?: string;
  expertise?: string;
  titles?: string;
  sortBy?: string;
  language?: string;
  yoeLevel?: string;
  dayName?: string;
}) => {
  return api
    .get("/v2/specialist", {
      params: {
        page: params.page,
        limit: params.limit,
        membership_name: params.membership,
        status: params.status ?? "active",
        keyword: params.keyword,
        expertise: params.expertise,
        specialist_title: params.titles,
        sort_by: params.sortBy,
        language: params.language,
        yoe_level: params.yoeLevel,
        day_name: params.dayName,
        lang: utils.getLangActive().toLowerCase(),
      },
    })
    .then((val) => val.data);
};
