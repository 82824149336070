import { Like1 } from "iconsax-react";
import { Link } from "react-router-dom";
import { ButtonBorder, ButtonBlack } from "./Button";
import Chip from "./Chip";
import { DividerVertical } from "./Divider";
import utils from "utils";
import { config } from "helper/config";

export function SpecialistItem(props: { value: any; className?: string }) {
  const value = props.value;
  const reviewPercentage =
    value.overall_review > 0
      ? `${Math.round((value.review / value.overall_review) * 100)}%`
      : "-";

  const getHourlyPrice = () => {
    for (const item of props.value.rates ?? []) {
      if (item.format_call === "video_call") {
        return item.price;
      }
    }

    return 0;
  };

  return (
    <div className={props.className}>
      <div className="bg-white p-6 rounded-2xl">
        <div className="flex flex-col justify-center items-center align-middle md:items-start md:flex-row">
          <div className="relative w-32 h-32 mx-auto mb-9 lg:mb-0 md:flex-shrink-0">
            <Link to={`/booking/profile/${value.member_code}`}>
              <img
                className="w-32 h-32 mb-3 rounded-full shadow-lg object-cover"
                src={`${config.AWS_URL_IMAGES}${value.profile_url}`}
                alt=""
              />
            </Link>
            <div className="absolute -translate-y-7 translate-x-4 rounded-full text-gray-700 bg-gray-100 px-5 py-1">
              {utils.getLangActive() === "EN"
                ? value.year_of_experience
                : value.year_of_experience
                    .replaceAll("Years", "Tahun")
                    .replaceAll("Year", "Tahun")}
            </div>
          </div>

          <div className="leading-normal text-center grow md:ml-8 md:text-left">
            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-gray-900">
              {value.username}
            </h5>
            <p className="mb-2 font-normal text-sm text-gray-700">
              {value.specialist_title}
            </p>
            <div className="flex justify-center items-center md:justify-start">
              <Like1 size="16" color="#767E8C" />
              <p className="ml-2 font-normal text-sm text-neutral-secondary">
                {reviewPercentage} ({value.overall_review}{" "}
                {utils.getLangByKey("global_reviews")})
              </p>
            </div>
            <p className="mb-3 mt-2 text-sm font-normal line-clamp-4">
              {value.bio}
            </p>
            <p className="mb-1 font-normal text-gray-700 text-xs mt-2">
              {utils.getLangByKey("global_expertise")}
            </p>
            {props.value.expertises ? (
              <div className="flex justify-center md:justify-start gap-2 flex-wrap">
                {value.expertises.slice(0, 3).map((val: any) => {
                  return <Chip key={val.expertise_code}>{val.name}</Chip>;
                })}
                {value.expertises.length > 3 ? (
                  <Chip>
                    +{value.expertises.length - 3}{" "}
                    {utils.getLangByKey("global_more")}
                  </Chip>
                ) : null}
              </div>
            ) : null}
          </div>

          <div className="mx-8 hidden md:block">
            <DividerVertical></DividerVertical>
          </div>

          <div className="border-b md:hidden w-full my-8"></div>

          <div className="flex-shrink-0 self-center flex flex-col gap-4 w-full md:w-auto">
            <div className="font-medium text-center text-2xl">
              {utils.currencyFormatter(getHourlyPrice())}/
              <span className="text-lg text-neutral-secondary font-medium">
                {utils.getLangByKey("global_session")}
              </span>
            </div>
            <Link to={`/booking/profile/${value.member_code}`}>
              <ButtonBorder>
                {utils.getLangByKey("search_items_view_profile")}
              </ButtonBorder>
            </Link>
            <Link to={`/booking/${value.member_code}`}>
              <ButtonBlack className="px-6 py-3 text-center">
                {utils.getLangByKey("search_items_book")}
              </ButtonBlack>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
