import utils from "utils";
import api from "./api";

export const getReservationOvertime = (params: { reservationCode: string }) => {
  return api
    .get(
      `/v1/members/specialist/reservation/${params.reservationCode}/overtime`,
      {
        params: {
          lang: utils.getLangActive().toLowerCase(),
        },
      }
    )
    .then((val) => val.data);
};

export const bookReservationOvertime = (params: {
  reservationCode: string;
  formatCall: string;
  paymentType: string;
  paymentChannel: string;
  paymentOption: string;
  paymentPhone?: string;
  promoCode?: string;
  promoApplied?: number;
}) => {
  return api
    .post(
      `/v1/members/specialist/reservation/${params.reservationCode}/overtime/book`,
      {
        format_call: params.formatCall,
        payment_type: params.paymentType,
        payment_channel: params.paymentChannel,
        payment_option: params.paymentOption,
        payment_phone: params.paymentPhone,
        promo_code: params.promoCode,
        promo_applied: params.promoApplied,
        lang: utils.getLangActive().toLowerCase(),
      }
    )
    .then((val) => val.data);
};
