import { ButtonBlue } from "components/Button";
import { InputText } from "components/Input";
import { config } from "helper/config";
import { Link } from "react-router-dom";
import utils from "utils";

export function LoginForm(props: {
  email: string;
  password: string;
  isLoading: boolean;
  isLoadingGoogle: boolean;
  isValidInput: boolean;
  setEmail: (_: string) => void;
  setPassword: (_: string) => void;
  setAuthType: (_: string) => void;
  onSubmit: () => void;
  onSubmitGoogle: () => void;
}) {
  return (
    <div className="p-8 bg-white lg:rounded-xl flex flex-col gap-6">
      <div className="font-medium text-xl text-black-app">
        {utils.getLangByKey("global_login")}
      </div>
      <div>
        <div className="mb-2">
          <label className="text-lg font-medium text-black-app" htmlFor="email">
            {utils.getLangByKey("global_email")}*
          </label>
        </div>
        <InputText
          placeholder={utils.getLangByKey("global_email")}
          onChange={(val) => props.setEmail(val)}
          defaultValue={props.email}
          type="email"
          id="email"
        ></InputText>
      </div>
      <div>
        <div className="mb-2">
          <label
            className="text-lg font-medium text-black-app"
            htmlFor="password"
          >
            {utils.getLangByKey("global_password")}*
          </label>
        </div>
        <InputText
          placeholder={utils.getLangByKey("global_password")}
          onChange={(val) => props.setPassword(val)}
          defaultValue={props.password}
          type="password"
          id="password"
        ></InputText>
      </div>

      <div className="flex items-center justify-end mt-2 cursor-pointer">
        <Link to={config.WEB_URL + "/forgot"} target="_top">
          <div className="font-medium text-sm text-brand-dark">
            {utils.getLangByKey("auth_login_forgot")}
          </div>
        </Link>
      </div>
      <div className="flex flex-row	items-center">
        <div className="flex-1 h-px bg-custom-border" />
        <div>
          <p className="w-auto px-4 text-base text-gray-400">
            {utils.getLangByKey("global_or")}
          </p>
        </div>
        <div className="flex-1 h-px bg-custom-border" />
      </div>
      <button
        type="button"
        className="text-white px-4 flex items-center justify-center bg-white border border-solid border-custom-border hover:bg-blue/10 rounded-full w-full h-12 mt-2"
        onClick={props.onSubmitGoogle}
      >
        {props.isLoadingGoogle ? (
          <div
            className="h-5 w-5 animate-spin rounded-full border-4 border-solid border-blue border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          ></div>
        ) : (
          <img
            className="h-[24px] w-[24px] cursor-pointer mr-4"
            src="/images/svg/ic_google.svg"
            alt=""
          />
        )}
        <span className="text-black font-medium">
          {props.isLoadingGoogle
            ? utils.getLangByKey("global_please_wait")
            : utils.getLangByKey("auth_login_with_google")}
        </span>
      </button>

      <div className="font-medium mt-8">
        {utils.getLangByKey("auth_login_no_account")}{" "}
        <span
          className="text-brand-dark cursor-pointer"
          onClick={() => props.setAuthType(config.constant.signup)}
        >
          {utils.getLangByKey("global_register")}
        </span>
      </div>
      <ButtonBlue
        className={`px-6 py-2 ${props.isValidInput ? "" : "bg-slate-300"}`}
        onClick={props.onSubmit}
      >
        {props.isLoading
          ? utils.getLangByKey("global_please_wait")
          : utils.getLangByKey("global_login")}
      </ButtonBlue>
    </div>
  );
}
