import api from "./api";
import utils from "utils";

export const getProfile = async () => {
  const res = await api.get("/v1/members/profile", {
    params: {
      lang: utils.getLangActive().toLowerCase(),
    },
  });
  return res.data;
};

export const updateProfile = (val: any) => {
  return api.put("/v1/members/profile", val).then((val) => val.data);
};

export const getLatestPoint = () => {
  return api.get("/v1/members/points/latest").then((val) => val.data);
};
