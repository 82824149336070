/* eslint-disable react-hooks/exhaustive-deps */
import {
  DateCalendar,
  DayCalendarSkeleton,
  LocalizationProvider,
  PickersDay,
  PickersDayProps,
} from "@mui/x-date-pickers";
import { ButtonBlack } from "components/Button";
import { Title } from "components/Text";
import Stepper from "components/stepper";
import { config } from "helper/config";
import { PlayCircle, Video } from "iconsax-react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { Link, useParams, useSearchParams } from "react-router-dom";
import {
  getAvailabilityFormatCall,
  getScheduleMonthly,
  getSchedules,
} from "service/schedule";
import { getSpecialist, getSpecialistRates } from "service/specialist";
import utils from "utils";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/id";
import "dayjs/locale/en";
import { toast } from "react-toastify";
import { getProfile } from "service/profile";
import { CardAppointment } from "components/CardAppointment";
import SkeletonPayment from "components/skeleton/SkeletonPayment";

const PREVIEW = "preview";

export default function Booking() {
  const params = useParams();
  const [searchQuery] = useSearchParams();
  const memberCode = params.member_code as string;

  const [calendarDate, setCalendarDate] = useState(new Date());

  const [value, setValue] = useState(new Date());
  const [appointmentType, setAppointmentType] = useState(
    searchQuery.get("appointmentType") ?? PREVIEW
  );
  const [scheduleDetailCode, setScheduleDetailCode] = useState("");
  const [currentTimezone, setCurrentTimezone] = useState("-");

  const getPrice = (appointmentType: string) => {
    const price = utils.currencyFormatter(
      profileRates.data.rates.find(
        (_data: { format_call: string }) =>
          _data.format_call === appointmentType
      )?.price
    );
    return price.replace(/\u00A0/, " ");
  };

  const onFetchRates = (memberCode: string) => {
    return () =>
      getSpecialistRates({
        memberCode: memberCode,
      }).then((val) => {
        return val.data;
      });
  };

  const profileRates = useQuery("getSpecialistRates", onFetchRates(memberCode));

  const onFetchProfile = () => {
    return () =>
      getSpecialist({ memberCode: memberCode }).then((val) => val.data);
  };

  const onFetchScheduleMonthly = () => {
    return getScheduleMonthly({
      memberCode: memberCode,
      formatCall: appointmentType,
      year: calendarDate.getFullYear(),
      month: calendarDate.getMonth() + 1,
    })
      .then((val) => val.data)
      .catch((err) => toast.error(err));
  };

  const [schedule, setSchedule] = useState<any>();
  const onFetchSchedule = () => {
    getSchedules({
      memberCode: memberCode,
      status: "active",
      format_call: appointmentType,
      date: moment(value.toISOString()).format("YYYY-MM-DD"),
    }).then((val) => {
      setSchedule(val.data);
    });
  };

  const queryProfile = useQuery("getSpecialistProfile", onFetchProfile());
  const profile = queryProfile.data;

  const queryScheduleMonthly = useQuery(
    "fetchScheduleMonthly",
    onFetchScheduleMonthly
  );

  const scheduleMonthly = queryScheduleMonthly.data ?? [];
  const isDisabled = scheduleDetailCode === "";

  const onFetchAvailabilityFormatCall = () => {
    return () =>
      getAvailabilityFormatCall({
        memberCode: memberCode,
        year: calendarDate.getFullYear(),
        month: calendarDate.getMonth() + 1,
      }).then((val) => {
        return val.data;
      });
  };

  const queryFormatCall = useQuery(
    "getAvailabilityFormatCall",
    onFetchAvailabilityFormatCall()
  );
  const formatCalls = queryFormatCall.data;

  const [showCalendar, setShowCalendar] = useState(true);

  useEffect(() => {
    if (showCalendar) queryFormatCall.refetch();
  }, [calendarDate]);

  useEffect(() => {
    if (appointmentType !== "" && showCalendar) queryScheduleMonthly.refetch();
  }, [calendarDate, appointmentType]);

  useEffect(() => {
    setScheduleDetailCode("");
    if (appointmentType !== "" && showCalendar) onFetchSchedule();
  }, [value]);

  useEffect(() => {
    getProfile()
      .then((val) => {
        const timezone = val.data.timezone;
        const timezoneFormat = `${timezone.title} (${
          timezone.zone
        } ${convertOffset(timezone.offset)})`;

        setCurrentTimezone(timezoneFormat);
      })
      .catch(() => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const timezoneFormat = `${timezone} ${new Date()
          .toTimeString()
          .slice(9)}`;

        setCurrentTimezone(timezoneFormat);
      });
  }, []);

  if (queryProfile.isLoading) {
    return <SkeletonPayment></SkeletonPayment>;
  }

  return (
    <div>
      {/* MODAL TIMEZONE */}
      {/* <ModalTimezone
        isShow={isShow}
        value={currentTimezone}
        timezones={queryTimezone.data ?? []}
        onClose={() => setShow(false)}
        onClick={(val) => setCurrentTimezone(val)}
      ></ModalTimezone> */}

      {/* CONTENT */}
      <Stepper step={1}></Stepper>
      <div className="flex flex-col lg:flex-row flex-wrap">
        <div className="p-8 bg-white rounded-xl lg:basis-7/12 mt-4 md:mt-0">
          <Title>{utils.getLangByKey("booking_appointment_title")}</Title>
          {formatCalls !== undefined ? (
            <div className="grid grid-cols-2 md:grid-cols-3 gap-4 mb-8">
              <CardAppointment
                icon={<PlayCircle className="w-5 md:w-8" variant="Bold" />}
                title={utils.getLangByKey("global_preview")}
                price={getPrice("preview")}
                isHidden={!formatCalls.preview}
                choosed={appointmentType === "preview"}
                onClick={() => {
                  setAppointmentType("preview");
                  setAppointmentType("preview");
                  setShowCalendar(true);
                  setCalendarDate(new Date());
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={
                  <img src="/images/svg/call.svg" alt="" className="w-6 h-6" />
                }
                title={utils.getLangByKey("global_voice_call")}
                price={getPrice("voice_call")}
                isHidden={
                  !formatCalls.voice_call || getPrice("voice_call") === "Rp 0"
                }
                choosed={appointmentType === "voice_call"}
                onClick={() => {
                  setAppointmentType("voice_call");
                  setAppointmentType("voice_call");
                  setShowCalendar(true);
                  setCalendarDate(calendarDate);
                  setSchedule([]);
                }}
              />
              <CardAppointment
                icon={<Video className="w-5 md:w-8" variant="Bold" />}
                title={utils.getLangByKey("global_video_call")}
                price={getPrice("video_call")}
                isHidden={
                  !formatCalls.video_call || getPrice("video_call") === "Rp 0"
                }
                choosed={appointmentType === "video_call"}
                onClick={() => {
                  setAppointmentType("video_call");
                  setAppointmentType("video_call");
                  setShowCalendar(true);
                  setCalendarDate(calendarDate);
                  setSchedule([]);
                }}
              />
            </div>
          ) : (
            <div></div>
          )}

          <Title>{utils.getLangByKey("booking_date_title")}</Title>

          {showCalendar ? (
            <LocalizationProvider
              adapterLocale={utils.getLangActive().toLowerCase()}
              dateAdapter={AdapterDayjs}
            >
              <DateCalendar
                className="!w-full"
                onMonthChange={(val) => {
                  setCalendarDate(val as Date);
                  setSchedule([]);
                }}
                onYearChange={(val) => setCalendarDate(val as Date)}
                renderLoading={() => <DayCalendarSkeleton />}
                slots={{ day: ServerDay } as any}
                sx={{
                  width: 1,
                  "& .MuiPickersFadeTransitionGroup-root ": {
                    width: 1,
                    display: "",
                  },
                  "& .MuiDayCalendar-header ": {
                    width: 1,
                    justifyContent: "space-between",
                  },
                  "& .MuiPickersSlideTransition-root ": {
                    width: 1,
                    display: "",
                  },
                  "& .MuiDayCalendar-weekContainer ": {
                    width: 1,
                    justifyContent: "space-between",
                  },
                }}
                slotProps={{
                  day: {
                    availables: scheduleMonthly,
                    onChange: (val: string) => {
                      setValue(new Date(val));
                    },
                  } as any,
                }}
              />
            </LocalizationProvider>
          ) : (
            <p className="mb-4 mt-2 text-sm">
              {utils.getLangByKey("booking_date_empty")}
            </p>
          )}

          <Title>{utils.getLangByKey("booking_time_title")}</Title>
          <div className="flex justify-between mb-4">
            <div className="text-neutral-secondary">
              {utils.getLangByKey("booking_time_timezone")} {currentTimezone}
            </div>
          </div>
          {(schedule ?? []).map((val: any, i: number) => {
            return (
              <div className="my-6" key={i}>
                <div className="mb-4 text-neutral-secondary">
                  {utils.getLangByKey(
                    "period_time_" + val.period.toLowerCase()
                  )}
                </div>
                <div className="grid grid-flow-row grid-cols-2 gap-6 sm:grid-cols-3 md:grid-cols-4">
                  {val.details.map((item: any, j: number) => {
                    return (
                      <div
                        key={`${i}-${j}`}
                        className={`py-2 text-center px-8 border rounded-lg cursor-pointer ${
                          item.schedule_detail_code === scheduleDetailCode
                            ? "border-black-app bg-blue-background"
                            : ""
                        }`}
                        onClick={() =>
                          setScheduleDetailCode(item.schedule_detail_code)
                        }
                      >
                        {moment(item.time_from, "HH:mm").format("hh:mm A")}
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
          <div className="flex justify-end">
            <Link
              to={
                isDisabled
                  ? "#"
                  : `/booking/${memberCode}/${scheduleDetailCode}?appointmentType=${appointmentType}`
              }
            >
              <ButtonBlack
                className={`py-3 px-8 ${!isDisabled ? "" : "bg-neutral-200"}`}
              >
                {utils.getLangByKey("global_next")}
              </ButtonBlack>
            </Link>
          </div>
        </div>
        <div className="order-first lg:order-last lg:basis-5/12 lg:pl-10">
          <div className="p-8 bg-white rounded-xl">
            <Title>{utils.getLangByKey("booking_detail_title")}</Title>
            <Link to={`/booking/profile/${memberCode}`}>
              <div className="flex flex-col gap-6 border rounded-xl px-6 py-4 items-start md:items-center md:flex-row">
                <div className="shrink-0">
                  <img
                    className="rounded-full w-16 h-16 object-cover object-top"
                    src={`${config.AWS_URL_IMAGES}${profile.profile_url}`}
                    alt=""
                  />
                </div>
                <div>
                  <div className="text-xl font-medium">{profile.username}</div>
                  <div className="text-neutral-secondary">
                    {profile.specialist_title}
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

function convertOffset(offset: number) {
  const hours = Math.round(offset / 3600);
  const minutes = Math.round(offset / 60) % 60;
  let hoursStr = "";
  let minutesStr = "";

  if (minutes < 10) {
    minutesStr += "0";
  }

  if (hours >= 0) {
    hoursStr += "+";
  } else {
    hoursStr += "-";
  }

  if (hours < 10 && hours > -10) {
    hoursStr += "0";
  }

  hoursStr += `${Math.abs(hours)}`;
  minutesStr += `${minutes}`;

  return `${hoursStr}:${minutesStr}`;
}

function ServerDay(
  props: PickersDayProps<Date> & {
    availables?: any[];
    onChange: (val: Date) => void;
  }
) {
  const { availables = [], day, outsideCurrentMonth, ...other } = props;

  const indexAvailable = availables.findIndex(
    (val) => val.date === dayjs(day).format("YYYY-MM-DD")
  );
  const isSelected = !props.outsideCurrentMonth && indexAvailable !== -1;

  return (
    <div className={`${isSelected ? "bg-blue-background rounded-full" : ""}`}>
      <PickersDay
        {...other}
        outsideCurrentMonth={outsideCurrentMonth}
        day={day}
        disabled={!isSelected}
        onClick={() => props.onChange(availables[indexAvailable].date)}
      />
    </div>
  );
}
