import { Lang } from "types/lang";

export const langData: Lang[] = [
  //Global
  {
    key: "global_lang_id",
    valueId: "Bahasa Indonesia",
    valueEn: "Indonesian",
  },
  {
    key: "global_lang_en",
    valueId: "Bahasa Inggris",
    valueEn: "English",
  },
  {
    key: "global_pay",
    valueId: "Bayar",
    valueEn: "Pay",
  },
  {
    key: "global_submit",
    valueId: "Kirim.",
    valueEn: "Submit.",
  },
  {
    key: "global_login",
    valueId: "Masuk",
    valueEn: "Sign In",
  },
  {
    key: "global_register",
    valueId: "Daftar",
    valueEn: "Sign Up",
  },
  {
    key: "global_or",
    valueId: "atau",
    valueEn: "or",
  },
  {
    key: "global_email",
    valueId: "Email",
    valueEn: "Email",
  },
  {
    key: "global_password",
    valueId: "Password",
    valueEn: "Password",
  },
  {
    key: "global_please_wait",
    valueId: "Tunggu Sebentar...",
    valueEn: "Please Wait...",
  },
  {
    key: "global_see_all",
    valueId: "Lihat lebih banyak",
    valueEn: "See All",
  },
  {
    key: "global_see_less",
    valueId: "Lihat lebih sedikit",
    valueEn: "See Less",
  },
  {
    key: "global_next",
    valueId: "Lanjut",
    valueEn: "Next",
  },
  {
    key: "global_appointment",
    valueId: "Janji Temu",
    valueEn: "Appointment",
  },
  {
    key: "global_date",
    valueId: "Tanggal",
    valueEn: "Date",
  },
  {
    key: "global_time",
    valueId: "Waktu",
    valueEn: "Time",
  },
  {
    key: "global_minutes",
    valueId: "menit",
    valueEn: "minutes",
  },
  {
    key: "global_education",
    valueId: "Pendidikan",
    valueEn: "Education",
  },
  {
    key: "global_language",
    valueId: "Bahasa",
    valueEn: "Language",
  },
  {
    key: "global_license",
    valueId: "Lisensi",
    valueEn: "License",
  },
  {
    key: "global_price",
    valueId: "Harga",
    valueEn: "Price",
  },
  {
    key: "global_preview",
    valueId: "Preview",
    valueEn: "Preview",
  },
  {
    key: "global_voice_call",
    valueId: "Panggilan Suara",
    valueEn: "Voice Call",
  },
  {
    key: "global_video_call",
    valueId: "Panggilan Video",
    valueEn: "Video Call",
  },
  {
    key: "global_session",
    valueId: "Sesi",
    valueEn: "Session",
  },
  {
    key: "global_found_helpful",
    valueId: "Merasa ini membantu",
    valueEn: "Found this helpful",
  },
  {
    key: "global_found_unhelpful",
    valueId: "Merasa ini tidak membantu",
    valueEn: "Found this not helpful",
  },
  {
    key: "global_reviews",
    valueId: "Ulasan",
    valueEn: "Reviews",
  },
  {
    key: "global_login",
    valueId: "Masuk",
    valueEn: "Login",
  },
  {
    key: "global_completed",
    valueId: "Selesai",
    valueEn: "Completed",
  },
  {
    key: "global_before",
    valueId: "Sebelum",
    valueEn: "Before",
  },
  {
    key: "global_payment_method",
    valueId: "Metode Pembayaran",
    valueEn: "Payment Method",
  },
  {
    key: "global_payment_link",
    valueId: "Tautan Pembayaran",
    valueEn: "Payment Link",
  },
  {
    key: "global_payment_total",
    valueId: "Total Pembayaran",
    valueEn: "Payment Total",
  },
  {
    key: "global_payment_instructions",
    valueId: "Instruksi Pembayaran",
    valueEn: "Payment Instructions",
  },
  {
    key: "global_back",
    valueId: "Kembali",
    valueEn: "Back",
  },
  {
    key: "global_experience",
    valueId: "Pengalaman",
    valueEn: "Experience",
  },
  {
    key: "global_expertise",
    valueId: "Keahlian",
    valueEn: "Expertise",
  },
  {
    key: "global_specialist",
    valueId: "Spesialis",
    valueEn: "Specialist",
  },
  {
    key: "global_filter",
    valueId: "Filter",
    valueEn: "Filter",
  },
  {
    key: "global_sort_by",
    valueId: "Urutkan",
    valueEn: "Sory By",
  },
  {
    key: "global_more",
    valueId: "Lainnya",
    valueEn: "More",
  },
  {
    key: "global_other",
    valueId: "Lainnya",
    valueEn: "Other",
  },
  {
    key: "global_skip",
    valueId: "Lewati",
    valueEn: "Skip",
  },
  {
    key: "global_continue",
    valueId: "Lanjut",
    valueEn: "Continue",
  },
  //Period Time
  {
    key: "period_time_morning",
    valueId: "Pagi",
    valueEn: "Morning",
  },
  {
    key: "period_time_afternoon",
    valueId: "Siang",
    valueEn: "Afternoon",
  },
  {
    key: "period_time_evening",
    valueId: "Sore",
    valueEn: "Evening",
  },
  {
    key: "period_time_night",
    valueId: "Malam",
    valueEn: "Night",
  },
  //Day
  {
    key: "day_monday",
    valueId: "Senin",
    valueEn: "Monday",
  },
  {
    key: "day_tuesday",
    valueId: "Selasa",
    valueEn: "Tuesday",
  },
  {
    key: "day_wednesday",
    valueId: "Rabu",
    valueEn: "Wednesday",
  },
  {
    key: "day_thursday",
    valueId: "Kamis",
    valueEn: "Thursday",
  },
  {
    key: "day_friday",
    valueId: "Jumat",
    valueEn: "Friday",
  },
  {
    key: "day_saturday",
    valueId: "Sabtu",
    valueEn: "Saturday",
  },
  {
    key: "day_sunday",
    valueId: "Minggu",
    valueEn: "Sunday",
  },
  //Category
  {
    key: "category_self",
    valueId: "👤️ Diri",
    valueEn: "👤️ Self",
  },
  {
    key: "category_partner",
    valueId: "💕 Pasangan",
    valueEn: "💕 Partner",
  },
  {
    key: "category_family",
    valueId: "👨‍👩‍👧‍👦 Keluarga",
    valueEn: "👨‍👩‍👧‍👦 Family",
  },
  {
    key: "category_friend",
    valueId: "👋🏻 Teman",
    valueEn: "👋🏻 Friends",
  },
  {
    key: "category_health",
    valueId: "🏋🏻‍♀️ Kesehatan",
    valueEn: "🏋🏻‍♀️ Health",
  },
  {
    key: "category_work",
    valueId: "💼 Pekerjaan",
    valueEn: "💼 Work",
  },
  {
    key: "category_education",
    valueId: "🏫 Pendidikan",
    valueEn: "🏫 Education",
  },
  {
    key: "category_finance",
    valueId: "💰 Keuangan",
    valueEn: "💰 Finance",
  },
  //Search
  {
    key: "search_title",
    valueId: "Spesialis kami ada di sini untuk kamu",
    valueEn: "Our specialists are here for you",
  },
  {
    key: "search_subtitle",
    valueId:
      "Temukan spesialis terbaik yang dapat membantu kamu untuk memenuhi kebutuhan dan tujuan kamu",
    valueEn:
      "Find the best specialist that will help you to meet your needs and goals",
  },
  {
    key: "search_hint",
    valueId: "Cari Spesialis",
    valueEn: "Search Specialist",
  },
  {
    key: "search_selected",
    valueId: "Filter yang Dipilih",
    valueEn: "Selected Filters",
  },
  {
    key: "search_selected",
    valueId: "Filter yang Dipilih",
    valueEn: "Selected Filters",
  },
  {
    key: "search_sort_by_opt_1",
    valueId: "Ketersediaan Tercepat",
    valueEn: "Fastest Availability",
  },
  {
    key: "search_sort_by_opt_2",
    valueId: "Rating Tertinggi",
    valueEn: "Highest Rating",
  },
  {
    key: "search_sort_by_opt_3",
    valueId: "Harga Rendah ke Tinggi",
    valueEn: "Price Low to High",
  },
  {
    key: "search_sort_by_opt_4",
    valueId: "Harga Tinggi ke Rendah",
    valueEn: "Price High to Low",
  },
  {
    key: "search_sort_by_opt_5",
    valueId: "Tahun Pengalaman",
    valueEn: "Years of Experience",
  },
  {
    key: "search_sort_by_opt_6",
    valueId: "Nama A ke Z",
    valueEn: "Name A to Z",
  },
  {
    key: "search_sort_by_opt_7",
    valueId: "Nama Z ke A",
    valueEn: "Name Z to A",
  },
  {
    key: "search_lang_title",
    valueId: "Bahasa Spesialis",
    valueEn: "Specialist Language",
  },
  {
    key: "search_lang_opt_1",
    valueId: "Bahasa Indonesia",
    valueEn: "Indonesian",
  },
  {
    key: "search_lang_opt_2",
    valueId: "Bahasa Inggris",
    valueEn: "English",
  },
  {
    key: "search_exp_title",
    valueId: "Pengalaman Spesialis",
    valueEn: "Specialist Experience",
  },
  {
    key: "search_exp_opt_1",
    valueId: "Spesialis Senior",
    valueEn: "Senior Specialist",
  },
  {
    key: "search_exp_opt_2",
    valueId: "Spesialis Junior",
    valueEn: "Junior Specialist",
  },
  {
    key: "search_day_title",
    valueId: "Ketersediaan Spesialis (Hari)",
    valueEn: "Specialist Availability (Day)",
  },
  {
    key: "search_type_title",
    valueId: "Tipe Spesialis",
    valueEn: "Specialist Type",
  },
  {
    key: "search_expertise_title",
    valueId: "Keahlian Spesialis",
    valueEn: "Specialist Expertise",
  },
  {
    key: "search_reset_all",
    valueId: "Atur Ulang Semua",
    valueEn: "Reset All",
  },
  {
    key: "search_no_result_title",
    valueId: "Hasil tidak ditemukan",
    valueEn: "No results found",
  },
  {
    key: "search_no_result_subtitle_only",
    valueId:
      "Pencarian Anda tidak menghasilkan hasil yang sama persis, coba cari spesialis lain untuk melihat hasil lebih banyak.",
    valueEn:
      "Your search returned no exact matches, try searching for another specialist to see more results.",
  },
  {
    key: "search_no_result_subtitle_1",
    valueId: "Pencarian Anda tidak menghasilkan hasil yang sama persis, coba",
    valueEn: "Your search returned no exact matches, try",
  },
  {
    key: "search_no_result_subtitle_2",
    valueId: "mengatur ulang filter Anda",
    valueEn: "resetting your filters",
  },
  {
    key: "search_no_result_subtitle_3",
    valueId:
      "untuk melihat hasil lebih lanjut. Berikut beberapa spesialis yang direkomendasikan.",
    valueEn: "to see more results. Here are some recommended specialists.",
  },
  {
    key: "search_items_view_profile",
    valueId: "Lihat Profil",
    valueEn: "View Profile",
  },
  {
    key: "search_items_book",
    valueId: "Pesan Jadwal",
    valueEn: "Book a Schedule",
  },
  {
    key: "profile_about_me",
    valueId: "Tentang Saya",
    valueEn: "About Me",
  },
  {
    key: "profile_expertise",
    valueId: "Apa yang kamu bisa tanyakan kepada Saya",
    valueEn: "What you can ask me about",
  },
  {
    key: "profile_approach",
    valueId: "Pendekatan yang saya gunakan",
    valueEn: "Which approach do I use",
  },
  {
    key: "profile_target",
    valueId: "Siapa yang dapat menghubungi saya",
    valueEn: "Who can reach out to me",
  },
  {
    key: "profile_button",
    valueId: "Pesan jadwal sekarang",
    valueEn: "Book a schedule now",
  },
  {
    key: "step_1",
    valueId: "Pilih Jadwal",
    valueEn: "Choose Schedule",
  },
  {
    key: "step_2",
    valueId: "Info kamu dan pembayaran",
    valueEn: "Your Info and payment",
  },
  {
    key: "step_3",
    valueId: "Pesanan Selesai",
    valueEn: "Booking Completed",
  },
  {
    key: "booking_detail_title",
    valueId: "Detail Pemesanan",
    valueEn: "Booking Details",
  },
  {
    key: "booking_detail_policy",
    valueId: "Pengalaman konsultasi kamu terjamin dengan",
    valueEn: "Your consultation experience is guaranteed with our",
  },
  {
    key: "booking_detail_policy_btn",
    valueId: "Kebijakan Konsultasi Aman kami",
    valueEn: "Safe Consultation Policy",
  },
  {
    key: "booking_appointment_title",
    valueId: "Pilih Janji Temu",
    valueEn: "Choose Appointment",
  },
  {
    key: "booking_date_title",
    valueId: "Pilih Tanggal",
    valueEn: "Choose Date",
  },
  {
    key: "booking_date_empty",
    valueId: "Silakan pilih jenis janji temu terlebih dahulu",
    valueEn: "Choose appointment type first please",
  },
  {
    key: "booking_time_title",
    valueId: "Pilih Waktu",
    valueEn: "Choose Time",
  },
  {
    key: "booking_time_timezone",
    valueId: "Waktu ditunjukkan dalam",
    valueEn: "Time shown in",
  },
  {
    key: "auth_register_title",
    valueId: "Informasi Daftar",
    valueEn: "Sign Up Information",
  },
  {
    key: "auth_register_name_title",
    valueId: "Nama",
    valueEn: "Name",
  },
  {
    key: "auth_register_have_account",
    valueId: "Sudah punya akun?",
    valueEn: "Already have an account?",
  },
  {
    key: "auth_register_with_google",
    valueId: "Daftar dengan Google",
    valueEn: "Sign Up with Google",
  },
  {
    key: "auth_login_no_account",
    valueId: "Belum punya akun?",
    valueEn: "Don’t have an account?",
  },
  {
    key: "auth_login_forgot",
    valueId: "Lupa Password?",
    valueEn: "Forgot Password?",
  },
  {
    key: "auth_login_with_google",
    valueId: "Masuk dengan Google",
    valueEn: "Sign In with Google",
  },
  {
    key: "verify_title",
    valueId: "Verifikasi Email Anda",
    valueEn: "Verify Your Email",
  },
  {
    key: "verify_subtitle",
    valueId: "Silakan masukkan 4 Digit kode yang dikirimkan ke",
    valueEn: "Please enter the 4 Digit code sent to",
  },
  {
    key: "verify_code_unreceived",
    valueId: "Tidak menerima kode?",
    valueEn: "Didn't receive the code?",
  },
  {
    key: "verify_code_resend",
    valueId: "Kirim Ulang Kode",
    valueEn: "Resend Code",
  },
  {
    key: "verify_please_wait",
    valueId: "Harap tunggu",
    valueEn: "Please Wait",
  },
  {
    key: "verify_time_wait",
    valueId: "detik untuk mengirim ulang kode",
    valueEn: "seconds to resend the code",
  },
  {
    key: "verify_btn_loading",
    valueId: "Memverifikasi...",
    valueEn: "Verifying...",
  },
  {
    key: "verify_btn",
    valueId: "Verifikasi",
    valueEn: "Verify",
  },
  {
    key: "safe_title",
    valueId: "Kebijakan Konsultasi Aman",
    valueEn: "Safe Consultation Policy",
  },
  {
    key: "safe_point_1",
    valueId:
      "Kerahasiaan Anda 100% dilindungi berdasarkan kode etik ketat kami",
    valueEn:
      "Your confidentiality is 100% safeguarded under our strict code of ethics",
  },
  {
    key: "safe_point_2",
    valueId:
      "Semua spesialis Maxi telah disaring dan diverifikasi secara menyeluruh",
    valueEn:
      "All specialists of Maxi have been thoroughly screened and verified",
  },
  {
    key: "safe_point_3",
    valueId: "Jaminan uang kembali jika Anda tidak puas dengan sesi Anda",
    valueEn: "Money back guarantee if you are not satisfied with your session",
  },
  {
    key: "safe_point_4",
    valueId: "Sesi dapat dijadwal ulang atau dibatalkan 6 jam sebelum dimulai",
    valueEn: "Session can be rescheduled or cancelled 6 hours before it starts",
  },
  {
    key: "loged_title",
    valueId: "Kamu masuk sebagai",
    valueEn: "You are logged in as",
  },
  {
    key: "loged_subtitle",
    valueId: "Jika ini bukan akun Anda.",
    valueEn: "If this is not your account.",
  },
  {
    key: "loged_switch",
    valueId: "Ganti akun",
    valueEn: "Switch Account",
  },
  {
    key: "booking_book_session",
    valueId: "Pesan Sesi",
    valueEn: "Book Session",
  },
  {
    key: "booking_ovo_title",
    valueId: "Masukkan nomor yang terdaftar di OVO",
    valueEn: "Enter the number registered with OVO",
  },
  {
    key: "booking_ovo_phone",
    valueId: "No HP",
    valueEn: "Mobile Phone",
  },
  {
    key: "booking_ovo_pay_now",
    valueId: "Bayar Sekarang",
    valueEn: "Pay Now",
  },
  {
    key: "booking_ovo_change_payment",
    valueId: "Ganti Pembayaran Lain",
    valueEn: "Change Payment",
  },
  {
    key: "booking_bank_transfer",
    valueId: "Transfer Bank",
    valueEn: "Bank Transfer",
  },
  {
    key: "booking_point_maxi",
    valueId: "Poin Maxi",
    valueEn: "Maxi Points",
  },
  {
    key: "booking_promo_hint",
    valueId: "Masukkan kode promo",
    valueEn: "Insert promo code",
  },
  {
    key: "booking_promo_claim",
    valueId: "Klaim",
    valueEn: "Claim",
  },
  {
    key: "booking_payment_ovo",
    valueId: "Masukkan No HP",
    valueEn: "Input Phone Number",
  },
  {
    key: "payment_waiting_title",
    valueId: "Selesaikan Pembayaranmu Dalam",
    valueEn: "Complete Your Payment In",
  },
  {
    key: "payment_waiting_already",
    valueId: "Sudah melakukan pembayaran?",
    valueEn: "Already made a payment?",
  },
  {
    key: "payment_waiting_update",
    valueId: "Perbarui status pembayaran",
    valueEn: "Update payment status",
  },
  {
    key: "payment_instruction_qris_1",
    valueId:
      "Scan/screenshot kode QR dengan mobile banking atau aplikasi pembayaran yang mendukung QRIS",
    valueEn:
      "Scan/screenshot the QR code with a mobile banking or payment application that supports QRIS",
  },
  {
    key: "payment_instruction_qris_2",
    valueId: "Periksa detail transaksi pada aplikasi",
    valueEn: "Check transaction details on the application",
  },
  {
    key: "payment_instruction_qris_3",
    valueId: "Klik tombol",
    valueEn: "Click button",
  },
  {
    key: "payment_instruction_qris_4",
    valueId:
      "Setelah pembayaran selesai, kamu akan mendapat notifikasi pembayaran",
    valueEn:
      "After payment is complete, you will receive a payment notification",
  },
  {
    key: "payment_cancel",
    valueId: "Batalkan Pesanan",
    valueEn: "Cancel booking",
  },
  {
    key: "payment_change",
    valueId: "Ganti Metode Pembayaran",
    valueEn: "Change payment method",
  },
  {
    key: "payment_complete_title",
    valueId: "Anda telah berhasil memesan jadwal",
    valueEn: "You have successfully booked a schedule",
  },
  {
    key: "payment_complete_subtitle",
    valueId: "Anda akan menerima bukti terima pemesanan ke",
    valueEn: "You will receive a booking receipt to",
  },
  {
    key: "payment_complete_subtitle_2",
    valueId: "segera.",
    valueEn: "shortly.",
  },
  {
    key: "payment_complete_gcal_title",
    valueId: "Tambahkan sesi Anda ke Google Kalender",
    valueEn: "Add your session to Google Calendar",
  },
  {
    key: "payment_complete_gcal_subtitle_1",
    valueId: "Untuk menerima tautan Google Meet, harap",
    valueEn: "To receive a Google Meet link, please",
  },
  {
    key: "payment_complete_gcal_subtitle_2",
    valueId: "tambahkan sesi Anda ke Google Kalender",
    valueEn: "add your session to Google Calendar",
  },
  {
    key: "payment_complete_gcal_subtitle_3",
    valueId: "sebelum sesi Anda dimulai.",
    valueEn: "before your session starts.",
  },
  {
    key: "payment_complete_gcal_add",
    valueId: "Tambahkan ke kalender Google",
    valueEn: "Add to Google calendar",
  },
  {
    key: "payment_complete_gcal_done_title",
    valueId: "Sesi Tautan Google Meet Anda",
    valueEn: "Your Google Meet Link Session",
  },
  {
    key: "payment_complete_gcal_done_title",
    valueId: "Sesi Tautan Google Meet Anda",
    valueEn: "Your Google Meet Link Session",
  },
  {
    key: "payment_complete_gcal_done_subtitle",
    valueId: "Tautan Google Meet",
    valueEn: "Google Meet link",
  },
  {
    key: "payment_complete_download_title",
    valueId: "Unduh Aplikasi Maxi untuk melacak sesi Anda dengan mudah",
    valueEn: "Download Maxi App to easily track your sessions",
  },
  {
    key: "payment_complete_download_point_1_1",
    valueId: "Masuk ke",
    valueEn: "Login to",
  },
  {
    key: "payment_complete_download_point_1_2",
    valueId: "Aplikasi Maxi Mobile",
    valueEn: "Maxi Mobile App",
  },
  {
    key: "payment_complete_download_point_1_3",
    valueId: "menggunakan email dan kata sandi Anda yang terdaftar",
    valueEn: "using your registered email and password",
  },
  {
    key: "payment_complete_download_point_2",
    valueId:
      "Simpan informasi pribadi untuk memudahkan pemesanan di masa mendatang",
    valueEn: "Save personal information for easier future booking",
  },
  {
    key: "payment_complete_download_point_3",
    valueId: "Simpan dan akses pemesanan Anda menggunakan Aplikasi Maxi",
    valueEn: "Save and access your bookings using Maxi App",
  },
  {
    key: "payment_complete_precouns_header",
    valueId: "Sebelum sesi Anda dimulai, harap mengisi Formulir Pra-Konseling",
    valueEn:
      "Before your session starts, please fill in the Pre-Counseling Form",
  },
  {
    key: "payment_complete_precouns_title",
    valueId: "Formulir Pra-Konseling",
    valueEn: "Pre-Counseling Form",
  },
  {
    key: "payment_complete_precouns_answer",
    valueId: "Jawaban Pra-Konseling",
    valueEn: "Pre-Counseling Answer",
  },
];
