import { Add, CalendarEdit, Lock, Verify, WalletMinus } from "iconsax-react";
import React, { useEffect, useState } from "react";
import utils from "utils";

export function ButtonModalPolicy() {
  const [isShowModalPolicy, setShowModalPolicy] = useState(false);

  return (
    <>
      <span
        className="text-brand-dark font-medium cursor-pointer"
        onClick={() => setShowModalPolicy(!isShowModalPolicy)}
      >
        {utils.getLangByKey("booking_detail_policy_btn")}
      </span>
      <ModalConsultationPolicy
        isShow={isShowModalPolicy}
        onClose={() => setShowModalPolicy(false)}
      ></ModalConsultationPolicy>
    </>
  );
}

export function ModalConsultationPolicy(props: {
  isShow: boolean;
  onClose: () => void;
}) {
  return (
    <ModalSimple
      title={utils.getLangByKey("safe_title")}
      isShow={props.isShow}
      onClose={props.onClose}
    >
      <div className="pl-6 pr-24 pb-14 space-y-6 ml-12">
        <div className="flex flex-col gap-8">
          <div className="flex gap-4">
            <Lock className="text-brand-dark" variant="Bold" size={32}></Lock>
            <div>{utils.getLangByKey("safe_point_1")}</div>
          </div>
          <div className="flex gap-4">
            <Verify
              className="text-brand-dark"
              variant="Bold"
              size={32}
            ></Verify>
            <div>{utils.getLangByKey("safe_point_2")}</div>
          </div>
          <div className="flex gap-4">
            <WalletMinus
              className="text-brand-dark"
              variant="Bold"
              size={32}
            ></WalletMinus>
            <div>{utils.getLangByKey("safe_point_3")}</div>
          </div>
          <div className="flex gap-4">
            <CalendarEdit
              className="text-brand-dark"
              variant="Bold"
              size={32}
            ></CalendarEdit>
            <div>{utils.getLangByKey("safe_point_4")}</div>
          </div>
        </div>
      </div>
    </ModalSimple>
  );
}

export function ModalSimple(props: {
  children: any;
  title: string;
  action?: React.ReactElement;
  isShow: boolean;
  onClose: () => void;
}) {
  useEffect(() => {
    if (props.isShow) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "unset";
    }
  }, [props.isShow]);

  return (
    <div className={props.isShow ? "" : "hidden"}>
      <div
        tabIndex={-1}
        aria-hidden="true"
        className="fixed top-0 left-0 right-0 bottom-0 z-50 w-full px-4 p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-[calc(100%-1rem)] max-h-full bg-black-app/50"
      >
        <div className="relative w-full max-w-2xl mx-auto top-1/2 -translate-y-1/2 max-h-full">
          {/* <!-- Modal content --> */}
          <div className="relative bg-white rounded-lg shadow">
            {/* <!-- Modal header --> */}
            <div className="flex items-start justify-between px-8 pt-8 pb-6 rounded-t">
              <h3 className="font-medium text-gray-900">{props.title}</h3>
              <button
                type="button"
                className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1 ml-auto inline-flex items-center"
                onClick={props.onClose}
              >
                <Add size={32} variant="Linear" className="rotate-45"></Add>
              </button>
            </div>
            {/* <!-- Modal body --> */}
            {props.children}

            {/* <!-- Modal footer --> */}
            {props.action}
          </div>
        </div>
      </div>
    </div>
  );
}
