import utils from "utils";

export default function Stepper(props: { step: number }) {
  const step = props.step;
  return (
    <div className="max-w-2xl mx-auto mb-24 px-4 md:px-6 lg:px-12">
      <ol className="flex items-center w-full font-medium text-center">
        <li
          className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-t-2 after:border-1 after:inline-block after:mx-6 ${
            step >= 1 ? "after:border-brand-dark" : ""
          }`}
        >
          <div className="after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200">
            <div className="font-medium text-lg rounded-full bg-brand-dark w-12 h-12 flex justify-center items-center">
              <div className="text-white">1</div>
              <div className="absolute translate-y-12 text-sm text-brand-dark w-24 md:w-32">
                {utils.getLangByKey("step_1")}
              </div>
            </div>
          </div>
        </li>
        <li
          className={`flex w-full items-center after:content-[''] after:w-full after:h-1 after:border-t-2 after:border-1 after:inline-block after:mx-6 ${
            step >= 2 ? "after:border-brand-dark" : ""
          }`}
        >
          <div className="after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200">
            <div
              className={`
                            font-medium text-lg rounded-full w-12 h-12 flex justify-center items-center
                            ${
                              step >= 2
                                ? "bg-brand-dark"
                                : "bg-neutral-secondary/10"
                            }
                        `}
            >
              <div
                className={`${
                  step >= 2 ? "text-white" : "text-neutral-secondary"
                }`}
              >
                2
              </div>
              <div
                className={`absolute translate-y-12 text-sm w-24 md:w-32 ${
                  step >= 2 ? "text-brand-dark" : "text-neutral-secondary"
                }`}
              >
                {utils.getLangByKey("step_2")}
              </div>
            </div>
          </div>
        </li>
        <li className="flex items-center">
          <div className="after:content-['/'] sm:after:hidden after:mx-2 after:text-gray-200">
            <div
              className={`
                            font-medium text-lg rounded-full w-12 h-12 flex justify-center items-center
                            ${
                              step >= 3
                                ? "bg-brand-dark"
                                : "bg-neutral-secondary/10"
                            }
                        `}
            >
              <div
                className={`${
                  step >= 3 ? "text-white" : "text-neutral-secondary"
                }`}
              >
                3
              </div>
              <div
                className={`absolute translate-y-12 text-sm w-24 md:w-32 ${
                  step >= 3 ? "text-brand-dark" : "text-neutral-secondary"
                }`}
              >
                {utils.getLangByKey("step_3")}
              </div>
            </div>
          </div>
        </li>
      </ol>
    </div>
  );
}
