/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonBlack } from "components/Button";
import Chip from "components/Chip";
import { config } from "helper/config";
import { DocumentText, Like1, PlayCricle, Video } from "iconsax-react";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  getSpecialist,
  getSpecialistRates,
  getSpecialistReview,
} from "service/specialist";
import utils from "utils";

import { useEffect, useState } from "react";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";
import SkeletonProfile from "components/skeleton/SkeletonProfile";

export default function Search() {
  const navigate = useNavigate();
  const params = useParams();
  const memberCode = params.member_code as string;

  const [reviewPage] = useState(1);
  const [reviewLimit, setReviewLimit] = useState(3);

  const onFetchProfile = (memberCode: string) => {
    return () =>
      getSpecialist({ memberCode: memberCode }).then((val) => {
        return val.data;
      });
  };

  const onFetchProfileReview = (memberCode: string) => {
    return () =>
      getSpecialistReview({
        memberCode: memberCode,
        page: reviewPage,
        limit: reviewLimit,
      }).then((val) => {
        return val.data;
      });
  };

  const profile = useQuery(
    "getSpecialistProfile",
    onFetchProfile(params.member_code as string)
  );
  const profileReview = useQuery(
    "getSpecialistProfileReview",
    onFetchProfileReview(memberCode)
  );
  const queryRates = useQuery("getSpecialistRate", () =>
    getSpecialistRates({ memberCode }).then((val) => val.data)
  );

  const getRate = (formatCall: string) => {
    if (!queryRates.data) {
      return 0;
    }

    for (const item of queryRates.data.rates ?? []) {
      if (item.format_call === formatCall) {
        return item.price;
      }
    }

    return 0;
  };

  const openAppointmentType = (appointment: string) => {
    navigate(`/booking/${memberCode}?appointmentType=${appointment}`);
  };

  useEffect(() => {
    profileReview.refetch();
  }, [reviewLimit]);

  const onReviewShowAll = (limit: number) => {
    if (limit > 3) {
      let top = 250;
      if (window.innerWidth <= 1024) {
        top = 500;
      }

      window.scrollBy({ top: top, behavior: "smooth" });
      utils.postMessageScrollBy(top);
    }
    setReviewLimit(limit);
  };

  if (!profile.data) {
    return <SkeletonProfile></SkeletonProfile>;
  }

  const reviewPercentage =
    profile.data.overall_review > 0
      ? `${Math.round(
          (profile.data.review / profile.data.overall_review) * 100
        )}%`
      : "-";

  return (
    <div>
      <div className="flex flex-col -mx-4 md:gap-8 md:mx-auto">
        <CardHeader
          profileUrl={`${config.AWS_URL_IMAGES}${profile.data.profile_url}`}
          name={profile.data.username}
          title={profile.data.specialist_title}
          yoe={profile.data.year_of_experience}
          reviewPercentage={reviewPercentage}
        ></CardHeader>
        <div></div>

        {profile.data.introduction_video_url ? (
          <div className="flex flex-col lg:flex-row">
            <div className="basis-1/2 shrink-0 lg:rounded-l-md overflow-hidden">
              <LiteYouTubeEmbed
                id={utils.extractYoutubeID(profile.data.introduction_video_url)}
                title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
              />
            </div>

            <Card className="w-full" title="About Me">
              {profile.data.bio}
            </Card>
          </div>
        ) : (
          <Card title={utils.getLangByKey("profile_about_me")}>
            {profile.data.bio}
          </Card>
        )}

        {profile.data.expertises ? (
          <Card title={utils.getLangByKey("profile_expertise")}>
            <div className="flex gap-4 flex-wrap">
              {profile.data.expertises.map((val: any) => {
                return <Chip key={val.expertise_code}>{val.name}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.approaches ? (
          <Card title={utils.getLangByKey("profile_approach")}>
            <div className="flex gap-5 flex-wrap">
              {profile.data.approaches.map((val: any) => {
                return <Chip key={val.approach_code}>{val.name}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.target_users ? (
          <Card title={utils.getLangByKey("profile_target")}>
            <div className="flex gap-5 flex-wrap">
              {profile.data.target_users.map((val: any) => {
                return <Chip key={val.target_code}>{val.name}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.universities && profile.data.universities.length > 0 ? (
          <Card title={utils.getLangByKey("global_education")}>
            <div className="flex flex-col gap-4">
              {(profile.data.universities ?? []).map((val: any, i: number) => {
                return (
                  <div key={i}>
                    <div className="font-medium">{val.university}</div>
                    <div className="flex gap-3 items-center text-neutral-secondary">
                      <div>{val.graduate_year}</div>
                      <div className="bg-neutral-300 h-2 w-2 rounded-full"></div>
                      <div>{val.degree}</div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.licenses && profile.data.licenses.length > 0 ? (
          <Card title={utils.getLangByKey("global_license")}>
            <div className="flex flex-col gap-4">
              {(profile.data.licenses ?? []).map((val: any, i: number) => {
                return (
                  <div className="flex" key={i}>
                    <DocumentText variant="Bold"></DocumentText>
                    <div className="ml-5">{`${val.license_name} - ${val.license_number}`}</div>
                  </div>
                );
              })}
            </div>
          </Card>
        ) : null}

        {profile.data.language ? (
          <Card title={utils.getLangByKey("global_language")}>
            <div className="flex gap-5 flex-wrap">
              {profile.data.language.map((val: string) => {
                return <Chip key={val}>{val}</Chip>;
              })}
            </div>
          </Card>
        ) : null}

        {profileReview.data ? (
          <CardReview
            overallReview={profile.data.overall_review}
            reviews={profileReview.data ?? []}
            onReviewShowAll={onReviewShowAll}
          ></CardReview>
        ) : null}

        <Card title={utils.getLangByKey("global_price")}>
          <div className="flex flex-col md:flex-row gap-6">
            {/* Preview Call */}
            <div
              className="w-full bg-blue-background rounded-lg p-8 border border-blue-background hover:border-blue-app cursor-pointer"
              onClick={() => openAppointmentType("preview")}
            >
              <div className="flex gap-8 items-center">
                <PlayCricle size={42} variant="Bold"></PlayCricle>
                <div>
                  <div className="mb-1 font-medium">
                    {utils.getLangByKey("global_preview")}
                  </div>
                  <div>{utils.currencyFormatter(getRate("preview"))}</div>
                </div>
              </div>
            </div>

            {/* Video Call */}
            <div
              className="w-full bg-blue-background rounded-lg p-8 border border-blue-background hover:border-blue-app cursor-pointer"
              onClick={() => openAppointmentType("video_call")}
            >
              <div className="flex gap-8 items-center">
                <Video size={42} variant="Bold"></Video>
                <div>
                  <div className="mb-1 font-medium">
                    {utils.getLangByKey("global_video_call")}
                  </div>
                  <div>{utils.currencyFormatter(getRate("video_call"))}</div>
                </div>
              </div>
            </div>

            {/* Voice Call */}
            <div
              className="w-full bg-blue-background rounded-lg p-8 border border-blue-background hover:border-blue-app cursor-pointer"
              onClick={() => openAppointmentType("voice_call")}
            >
              <div className="flex gap-8 items-center">
                <img src="/images/svg/call.svg" alt="" />
                <div>
                  <div className="mb-1 font-medium">
                    {utils.getLangByKey("global_voice_call")}
                  </div>
                  <div>{utils.currencyFormatter(getRate("voice_call"))}</div>
                </div>
              </div>
            </div>
          </div>
        </Card>
      </div>

      <div className="flex justify-center mt-20">
        <Link to={`/booking/${memberCode}`}>
          <ButtonBlack className="px-6 py-3">
            {utils.getLangByKey("profile_button")}
          </ButtonBlack>
        </Link>
      </div>
    </div>
  );
}

function Card(props: { title: string; children: any; className?: string }) {
  return (
    <div className={`bg-white p-8 rounded-xl ${props.className ?? ""}`}>
      <div className="mb-6 text-xl font-medium">{props.title}</div>
      <div>{props.children}</div>
    </div>
  );
}

function CardReview(props: {
  overallReview: number;
  reviews: any[];
  onReviewShowAll: (_: number) => void;
}) {
  return (
    <div className="bg-white p-8 rounded-xl">
      <div className="flex justify-between mb-6">
        <div className="flex gap-2 items-center">
          <div className="text-xl font-medium">
            {utils.getLangByKey("global_reviews")}
          </div>
          <div className="text-neutral-secondary">
            ({props.overallReview} {utils.getLangByKey("global_reviews")})
          </div>
        </div>
        {props.overallReview > 3 ? (
          <div
            className="text-blue-500 cursor-pointer"
            onClick={() =>
              props.onReviewShowAll(
                props.reviews.length > 3 ? 3 : props.overallReview
              )
            }
          >
            {props.reviews.length > 3
              ? utils.getLangByKey("global_see_less")
              : utils.getLangByKey("global_see_all")}
          </div>
        ) : null}
      </div>
      <div className="flex flex-col flex-wrap lg:flex-row -mx-2">
        {props.reviews.map((val: any, index) => {
          return (
            <div
              className={`w-full lg:basis-1/3 flex-shrink-0 overflow-hidden p-2`}
              key={index}
            >
              <div className="flex flex-col justify-between bg-blue-background h-full p-6 rounded-lg">
                <div className="opacity-60 overflow-hidden">
                  {val.review === "" ? "-" : val.review}
                </div>
                <div className="flex items-center gap-4 border-t pt-4 mt-6 text-neutral-secondary">
                  <div className="w-12 h-12 rounded-full bg-slate-200 shrink-0 text-black-app flex justify-center items-center text-2xl font-semibold">
                    {val.member_name[0].toUpperCase()}
                  </div>
                  <div className="flex gap-2">
                    <Like1
                      className={`${val.rating ? "" : "rotate-180"}`}
                    ></Like1>
                    <div>
                      {val.rating
                        ? utils.getLangByKey("global_found_helpful")
                        : utils.getLangByKey("global_found_unhelpful")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

function CardHeader(props: {
  profileUrl: string;
  name: string;
  title: string;
  yoe: string;
  reviewPercentage: string;
}) {
  return (
    <div className="rounded-xl bg-white overflow-hidden">
      <img
        className="h-36 w-full object-cover"
        src="/images/svg/bg-header.svg"
        alt=""
      />
      <div className="flex flex-col items-center md:flex-row">
        <div className="md:ml-12 md:items-start">
          <div className="absolute -translate-y-20 -translate-x-1/2 z-10 md:-translate-y-44 md:translate-x-0">
            <div className="rounded-full flex items-center overflow-hidden w-48 h-48 bg-white">
              <img
                className="rounded-full w-40 h-40 shrink-0 mx-auto object-cover"
                src={props.profileUrl}
                alt=""
              />
            </div>
          </div>
        </div>
        <div className="p-8 flex-grow mt-24 text-center md:text-left md:mt-0 md:ml-48">
          <div className="mb-3 text-3xl font-medium">{props.name}</div>
          <div className="mb-4 text-neutral-secondary">{props.title}</div>
          <div className="flex justify-between">
            <div className="flex gap-4">
              <div>
                {utils.getLangActive() === "EN"
                  ? props.yoe
                  : props.yoe
                      .replaceAll("Years", "Tahun")
                      .replaceAll("Year", "Tahun")}{" "}
                {utils.getLangByKey("global_experience")}
              </div>
              <div className="ml-4">
                <Like1></Like1>
              </div>
              <div>{props.reviewPercentage}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
