/* eslint-disable react-hooks/exhaustive-deps */
import { ButtonBlack } from "components/Button";
import { InputSearch } from "components/Input";
import { SpecialistItem } from "components/Specialist";
import {
  CategoryItem,
  CheckboxItem,
  SearchExpandable,
} from "components/search";
import SkeletonBooking from "components/skeleton/SkeletonBooking";
import { Add, SearchNormal1, Setting4 } from "iconsax-react";
import { useEffect, useMemo, useState } from "react";
import { useQuery } from "react-query";
import { useSearchParams } from "react-router-dom";
import { getExpertises } from "service/expertise";
import { getSpecialists } from "service/specialist";
import { fetchTitle } from "service/title";
import utils from "utils";

const WeekDays = [
  utils.getLangByKey("day_sunday"),
  utils.getLangByKey("day_monday"),
  utils.getLangByKey("day_tuesday"),
  utils.getLangByKey("day_wednesday"),
  utils.getLangByKey("day_thursday"),
  utils.getLangByKey("day_friday"),
  utils.getLangByKey("day_saturday"),
];

const ListSortby = [
  {
    value: "fastest_available",
    title: utils.getLangByKey("search_sort_by_opt_1"),
  },
  {
    value: "rating",
    title: utils.getLangByKey("search_sort_by_opt_2"),
  },
  {
    value: "price_asc",
    title: utils.getLangByKey("search_sort_by_opt_3"),
  },
  {
    value: "price_desc",
    title: utils.getLangByKey("search_sort_by_opt_4"),
  },
  {
    value: "yoe",
    title: utils.getLangByKey("search_sort_by_opt_5"),
  },
  {
    value: "name_asc",
    title: utils.getLangByKey("search_sort_by_opt_6"),
  },
  {
    value: "name_desc",
    title: utils.getLangByKey("search_sort_by_opt_7"),
  },
];

const DefaultLimit = 15;

function splitString(val: string): string[] {
  if (val === "") {
    return [];
  }

  return val.split(",");
}

export default function Search() {
  const [params] = useSearchParams();

  const [notFound, setNotFound] = useState(false);
  const [notFoundExact, setNotFoundExact] = useState(false);

  // Properties
  const [filterExpertises, setFilterExpertises] = useState<string[]>(
    splitString(params.get("expertise") ?? "")
  );
  const [filterTitles, setFilterTitles] = useState<string[]>(
    splitString(params.get("title") ?? "")
  );
  const [filterSortby, setFilterSortby] = useState("");
  const [filterLanguage, setFilterLanguage] = useState(
    params.get("language") ?? ""
  );
  const [filterDays, setFilterDays] = useState<string[]>(
    splitString(params.get("day") ?? "")
  );
  const [filterExperience, setFilterExperience] = useState(
    params.get("experience") ?? ""
  );

  const [search, setSearch] = useState("");
  const [menuSpecialist, setMenuSpecialist] = useState(-1);
  const [openFilter, setOpenFilter] = useState(false);
  const [limit, setLimit] = useState(DefaultLimit);

  // Callback
  const onChangeExpertise = (val: string) => {
    const expertiseIndex = filterExpertises.findIndex((v) => v === val);
    if (expertiseIndex !== -1) {
      filterExpertises.splice(expertiseIndex, 1);
    } else {
      filterExpertises.push(val);
    }

    setFilterExpertises([...filterExpertises]);
  };

  const onChangeTitle = (val: string) => {
    const titleIndex = filterTitles.findIndex((v) => v === val);
    if (titleIndex !== -1) {
      filterTitles.splice(titleIndex, 1);
    } else {
      filterTitles.push(val);
    }

    setFilterTitles([...filterTitles]);
  };

  const onChangeDays = (val: string) => {
    const index = filterDays.findIndex((item) => item === val);
    if (index === -1) {
      filterDays.push(val);
    } else {
      filterDays.splice(index, 1);
    }

    setFilterDays([...filterDays]);
  };

  const onFetchSpecialist = async () => {
    setNotFound(false);
    setNotFoundExact(false);
    let data = await getSpecialists({
      limit: limit,
      page: 1,
      expertise: filterExpertises.join(","),
      titles: filterTitles.join(","),
      sortBy: filterSortby,
      language: filterLanguage,
      yoeLevel: filterExperience,
      dayName: filterDays.join(","),
      keyword: search.trim(),
    });
    if (data.pagination.count > 0) {
      return data;
    }

    if (search.trim() !== "") {
      setNotFoundExact(true);
      return data;
    }

    // Big 3 filter
    setNotFound(true);
    data = await getSpecialists({
      limit: limit,
      page: 1,
      expertise: filterExpertises.join(","),
      titles: filterTitles.join(","),
      yoeLevel: filterExperience,
    });
    if (data.pagination.count > 0) {
      return data;
    }

    // Big 2 filter
    data = await getSpecialists({
      limit: limit,
      page: 1,
      titles: filterTitles.join(","),
      yoeLevel: filterExperience,
    });
    if (data.pagination.count > 0) {
      return data;
    }

    // Big 1 Filter
    data = await getSpecialists({
      limit: limit,
      page: 1,
      titles: filterTitles.join(","),
    });
    if (data.pagination.count > 0) {
      return data;
    }

    // No Filter
    return getSpecialists({
      limit: limit,
      page: 1,
    });
  };

  // Query
  const expertises = useQuery("fetchExpertise", () =>
    getExpertises().then((val) => val.data)
  );
  const specialists = useQuery("fetchSpecialist", onFetchSpecialist);
  const titles = useQuery("fetchTitle", () =>
    fetchTitle().then((val) => val.data)
  );

  const pagination = (specialists.data ?? {}).pagination ?? {};
  const isEnableMoreButton =
    (pagination.count ?? 0) > (pagination.limit ?? 0) * pagination.page;

  useEffect(() => {
    specialists.refetch();
  }, [limit]);

  useEffect(() => {
    onApplyFilter();
  }, [
    filterSortby,
    filterLanguage,
    filterExperience,
    filterDays,
    filterTitles,
    filterExpertises,
  ]);

  useEffect(() => {
    onSearch();
  }, [search]);

  const getExpertiseTitle = (val: string) => {
    for (const category of expertises.data ?? []) {
      for (const item of category.expertises) {
        if (item.expertise_code === val) {
          return item.name;
        }
      }
    }

    return "-";
  };

  const onSearch = () => {
    specialists.refetch();
  };

  const onApplyFilter = () => {
    specialists.refetch();

    window.scrollTo({ behavior: "smooth", top: 0 });
    utils.postMessageScrollTop();
  };

  const resetFilter = () => {
    setFilterExpertises([]);
    setFilterTitles([]);
    setFilterSortby("");
    setFilterDays([]);
    setFilterExperience("");
    setFilterLanguage("");
  };

  const onResetFilter = () => {
    resetFilter();
    setSearch("");

    // Wait for change
    setTimeout(() => {
      specialists.refetch();

      window.scrollTo({ top: 0, behavior: "smooth" });
      utils.postMessageScrollTop();
    }, 200);
  };

  const isSelectedFilter = () => {
    return (
      filterSortby !== "" ||
      filterLanguage !== "" ||
      filterExperience !== "" ||
      filterDays.length > 0 ||
      filterTitles.length > 0 ||
      filterExpertises.length > 0
    );
  };

  const onRefetch = () => {
    if (!isEnableMoreButton) {
      return;
    }

    setLimit(limit + 5);
  };

  const ExpertiseMemo = useMemo(() => {
    return (
      <>
        {(expertises.data ?? []).map((val: any, i: number) => {
          const isOpen = menuSpecialist === i;

          return (
            <CategoryItem
              key={val.category.category_code}
              value={val}
              expertises={filterExpertises}
              onChangeExpertise={onChangeExpertise}
              onClick={() => {
                if (isOpen) {
                  setMenuSpecialist(-1);
                } else {
                  setMenuSpecialist(i);
                }
              }}
              isOpen={isOpen}
            ></CategoryItem>
          );
        })}
      </>
    );
  }, [filterExpertises, expertises.data, menuSpecialist]);

  const FilterMenu = () => {
    return (
      <div className="bg-white rounded-lg">
        <div className="p-6 bg-neutral-app flex justify-between">
          <div className="flex justify-between w-full">
            <div className="flex items-center">
              <Setting4 variant="Linear"></Setting4>
              <div className="ml-4 font-medium text-xl">Filter</div>
            </div>
            <div className="lg:hidden" onClick={() => setOpenFilter(false)}>
              <Add size={42} variant="Linear" className="rotate-45"></Add>
            </div>
          </div>
        </div>

        <div className="mx-6 py-5">
          <SearchExpandable title={utils.getLangByKey("global_sort_by")}>
            <div className="ml-2">
              {ListSortby.map((val, i) => {
                return (
                  <div
                    key={i}
                    className={`cursor-pointer py-2 rounded-lg px-3 my-1 ${
                      filterSortby === val.value ? "bg-slate-100" : ""
                    }`}
                    onClick={() => setFilterSortby(val.value)}
                  >
                    {val.title}
                  </div>
                );
              })}
            </div>
          </SearchExpandable>

          <SearchExpandable title={utils.getLangByKey("search_lang_title")}>
            <CheckboxItem
              id="id"
              title={utils.getLangByKey("search_lang_opt_1")}
              isActive={filterLanguage === "Indonesian"}
              onChange={() => setFilterLanguage("Indonesian")}
            ></CheckboxItem>

            <CheckboxItem
              id="en"
              title={utils.getLangByKey("search_lang_opt_2")}
              isActive={filterLanguage === "English"}
              onChange={() => setFilterLanguage("English")}
            ></CheckboxItem>
          </SearchExpandable>

          <SearchExpandable title={utils.getLangByKey("search_exp_title")}>
            <CheckboxItem
              id="senior"
              title={utils.getLangByKey("search_exp_opt_1")}
              isActive={filterExperience === "senior"}
              onChange={() => setFilterExperience("senior")}
            ></CheckboxItem>

            <CheckboxItem
              id="junior"
              title={utils.getLangByKey("search_exp_opt_2")}
              isActive={filterExperience === "junior"}
              onChange={() => setFilterExperience("junior")}
            ></CheckboxItem>
          </SearchExpandable>

          <SearchExpandable title={utils.getLangByKey("search_day_title")}>
            {WeekDays.map((val) => {
              return (
                <CheckboxItem
                  key={val}
                  id={val}
                  title={val}
                  isActive={filterDays.includes(val)}
                  onChange={() => onChangeDays(val)}
                ></CheckboxItem>
              );
            })}
          </SearchExpandable>

          <SearchExpandable title={utils.getLangByKey("search_type_title")}>
            {(titles.data ?? []).map((val: any, i: number) => {
              return (
                <CheckboxItem
                  title={val.title}
                  isActive={filterTitles.includes(val.specialist_title_code)}
                  onChange={() => onChangeTitle(val.specialist_title_code)}
                  id={val.specialist_title_code}
                  key={val.specialist_title_code}
                ></CheckboxItem>
              );
            })}
          </SearchExpandable>

          <SearchExpandable
            title={utils.getLangByKey("search_expertise_title")}
          >
            {ExpertiseMemo}
          </SearchExpandable>
        </div>
      </div>
    );
  };

  if (expertises.isLoading || specialists.isLoading) {
    return <SkeletonBooking></SkeletonBooking>;
  }

  return (
    <div>
      <div className="mb-8">
        <SectionHeader></SectionHeader>
      </div>
      <div className="flex justify-between items-center gap-4 mb-6">
        <div className="font-semibold text-3xl hidden lg:block">
          {utils.getLangByKey("global_specialist")}
        </div>

        <div className="grow lg:grow-0 lg:w-96">
          <InputSearch
            value={search}
            onChange={setSearch}
            onSubmit={onSearch}
          ></InputSearch>
        </div>
        <div
          className="flex items-center bg-white py-3 rounded-lg px-4 lg:hidden"
          onClick={() => setOpenFilter(true)}
        >
          <Setting4 variant="Linear"></Setting4>
          <div className="ml-4 font-medium text-xl">
            {utils.getLangByKey("global_filter")}
          </div>
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-8">
        <div
          className="fixed top-0 left-0 right-0 bottom-0 z-10 bg-white lg:hidden"
          hidden={!openFilter}
        >
          <FilterMenu></FilterMenu>
        </div>

        <div className="flex-shrink-0 w-80 hidden lg:block">
          <FilterMenu></FilterMenu>
        </div>

        <div className="flex-grow">
          {isSelectedFilter() ? (
            <div className="flex mb-8 items-center gap-4 flex-wrap">
              <div className="font-medium text-neutral-black">
                {utils.getLangByKey("search_selected")} :
              </div>
              {filterSortby !== "" ? (
                <BadgeFilter
                  title={
                    ListSortby.filter((val) => val.value === filterSortby)[0]
                      .title
                  }
                  onClose={() => setFilterSortby("")}
                ></BadgeFilter>
              ) : null}
              {filterLanguage !== "" ? (
                <BadgeFilter
                  title={filterLanguage}
                  onClose={() => setFilterLanguage("")}
                ></BadgeFilter>
              ) : null}
              {filterExperience !== "" ? (
                <BadgeFilter
                  title={
                    filterExperience === "senior"
                      ? utils.getLangByKey("search_exp_opt_1")
                      : utils.getLangByKey("search_exp_opt_2")
                  }
                  onClose={() => setFilterExperience("")}
                ></BadgeFilter>
              ) : null}
              {filterDays.length > 0
                ? filterDays.map((item, i) => {
                    return (
                      <BadgeFilter
                        key={i}
                        title={item}
                        onClose={() =>
                          setFilterDays(
                            filterDays.filter((val) => val !== item)
                          )
                        }
                      ></BadgeFilter>
                    );
                  })
                : null}
              {filterTitles.length > 0
                ? filterTitles.map((item, i) => {
                    return (
                      <BadgeFilter
                        key={i}
                        title={
                          (titles.data ?? []).filter(
                            (val: any) => val.specialist_title_code === item
                          )[0].title
                        }
                        onClose={() =>
                          setFilterTitles(
                            filterTitles.filter((val) => val !== item)
                          )
                        }
                      ></BadgeFilter>
                    );
                  })
                : null}
              {filterExpertises.length > 0
                ? filterExpertises.map((item, i) => {
                    return (
                      <BadgeFilter
                        key={i}
                        title={getExpertiseTitle(item)}
                        onClose={() =>
                          setFilterExpertises(
                            filterExpertises.filter((val) => val !== item)
                          )
                        }
                      ></BadgeFilter>
                    );
                  })
                : null}

              <div
                className="cursor-pointer text-brand-dark"
                onClick={onResetFilter}
              >
                {utils.getLangByKey("search_reset_all")}
              </div>
            </div>
          ) : null}

          {notFound ? (
            <div className="flex gap-4 bg-neutral-background px-6 py-4 mb-8 rounded-lg">
              <SearchNormal1 size={32} variant="Linear"></SearchNormal1>
              <div>
                <div className="font-medium text-lg">
                  {utils.getLangByKey("search_no_result_title")}
                </div>
                <div className="font-light mt-1">
                  {utils.getLangByKey("search_no_result_subtitle_1")}{" "}
                  <span
                    className="text-brand-dark cursor-pointer"
                    onClick={onResetFilter}
                  >
                    {utils.getLangByKey("search_no_result_subtitle_2")}
                  </span>{" "}
                  {utils.getLangByKey("search_no_result_subtitle_3")}
                </div>
              </div>
            </div>
          ) : null}

          {notFoundExact ? (
            <div className="flex gap-4 bg-neutral-background px-6 py-4 mb-8 rounded-lg">
              <SearchNormal1 size={32} variant="Linear"></SearchNormal1>
              <div>
                <div className="font-medium text-lg">
                  {utils.getLangByKey("search_no_result_title")}
                </div>
                <div className="font-light mt-1">
                  {utils.getLangByKey("search_no_result_subtitle_only")}
                </div>
              </div>
            </div>
          ) : null}

          {(specialists.data.data ?? []).map((val: any) => {
            return (
              <SpecialistItem
                value={val}
                className="mb-8"
                key={val.member_code}
              ></SpecialistItem>
            );
          })}

          {notFound ||
          notFoundExact ||
          pagination.count < DefaultLimit ? null : (
            <div className="flex justify-center mt-12">
              <ButtonBlack
                className={`py-3 px-12 ${
                  isEnableMoreButton ? "" : "!bg-neutral-300"
                }`}
                onClick={onRefetch}
              >
                Show More
              </ButtonBlack>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

function BadgeFilter(props: { title: string; onClose: () => void }) {
  return (
    <div
      className="bg-white px-3 py-2 flex text-sm rounded-lg font-medium items-center"
      style={{
        color: "#4E5259",
      }}
    >
      <div>{props.title}</div>
      <div className="ml-4 cursor-pointer" onClick={props.onClose}>
        <img src="/images/svg/close-x.svg" alt="" />
      </div>
    </div>
  );
}

function SectionHeader(props: { className?: string }) {
  return (
    <section className={`${props.className}`}>
      <article>
        <div className="max-w-7xl mx-auto px-6 py-8 text-center">
          <h1 className="text-4xl p-0 !leading-normal font-semibold md:text-5xl">
            {utils.getLangByKey("search_title")}
          </h1>
          <div className="text-lg text-neutral-secondary mt-5">
            {utils.getLangByKey("search_subtitle")}
          </div>
        </div>
      </article>
    </section>
  );
}
