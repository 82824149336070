import api from "./api";
import utils from "utils";

export const getAvailabilityFormatCall = async (params: {
  memberCode: string;
  year: number;
  month: number;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/format_call`,
    {
      params: {
        year: params.year,
        month: params.month,
        lang: utils.getLangActive().toLowerCase(),
      },
    }
  );

  return resp.data;
};

export const getSchedules = async (params: {
  memberCode: string;
  status?: string;
  format_call?: string;
  date: string;
}) => {
  const resp = await api.get(`/v2/specialist/${params.memberCode}/schedule`, {
    params: {
      status: params.status,
      format_call: params.format_call,
      date: params.date,
      lang: utils.getLangActive().toLowerCase(),
    },
  });

  return resp.data;
};

export const getSchedule = async (params: {
  memberCode: string;
  scheduleDetailCode: string;
}) => {
  const resp = await api.get(
    `/v2/specialist/${params.memberCode}/schedule/${params.scheduleDetailCode}`
  );

  return resp.data;
};

export const getScheduleMonthly = async (params: {
  memberCode: string;
  formatCall: string;
  year: number;
  month: number;
}) => {
  return api
    .get(`/v2/specialist/${params.memberCode}/schedule/monthly`, {
      params: {
        format_call: params.formatCall,
        year: params.year,
        month: params.month,
        lang: utils.getLangActive().toLowerCase(),
      },
    })
    .then((val) => val.data);
};

export const getScheduleByReservation = async (params: {
  reservationCode: string;
}) => {
  return api
    .get(
      `/v1/members/specialist/reservation/${params.reservationCode}/schedule`,
      {
        params: {
          lang: utils.getLangActive().toLowerCase(),
        },
      }
    )
    .then((val) => val.data);
};
